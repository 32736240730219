import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.geekblue1} !important;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;

  .not-found-card-container {
    max-width: 380px;
    width: 100%;
    text-align: center;
    padding: 40px 12px;

    > img {
      width: 306px;
      height: 230px;
    }

    > .title {
      margin-bottom: 6px;
    }

    > .text-body {
      color: rgba(0, 0, 0, 0.45);
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin: 24px 0px 0px;
    }
  }
`
