import Layout1 from '@/layouts/Layout1'
import AccountCreationContent from './components/AccountCreationContent'
import AuthBg from '@/assets/images/auth/auth-bg.png'
import { Container } from './styles'

const AccountCreationPage = () => {
  return (
    <Layout1 navbar>
      <Container>
        <div className="left-content" style={{ backgroundImage: `url(${AuthBg})` }}>
          <div className="content">
            <h1 className="title">Upskill your team with Real Life projects.</h1>
          </div>
        </div>

        <div className="right-content">
          <div className="content">
            <AccountCreationContent />
          </div>
        </div>
      </Container>
    </Layout1>
  )
}

export default AccountCreationPage
