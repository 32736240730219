import {
  APP_ERROR_UPDATED,
  APP_NOTIFICATION_UPDATED,
  APP_THEME_UPDATED,
  APP_THEME_CONFIG_UPDATED,
  TOGGLE_REPORT_ISSUE_MODAL,
  TOGGLE_UPGRADE_TO_PRO_MODAL,
} from '@/store/app'

const setAppError = (error) => (dispatch) => {
  dispatch(APP_ERROR_UPDATED(error))
}

const setAppNotification = (notification) => (dispatch) => {
  dispatch(APP_NOTIFICATION_UPDATED(notification))
}

const setAppTheme = (theme) => (dispatch) => {
  localStorage.setItem('dw-theme', theme)
  dispatch(APP_THEME_UPDATED(theme))
}

const setAppThemeConfig = (themeConfig) => (dispatch) => {
  dispatch(APP_THEME_CONFIG_UPDATED(themeConfig))
}

const toggleReportIssueModal = (data) => (dispatch) => {
  dispatch(TOGGLE_REPORT_ISSUE_MODAL(data))
}

const toggleUpgradeToProModal = (isOpen) => (dispatch) => {
  dispatch(TOGGLE_UPGRADE_TO_PRO_MODAL(isOpen))
}

export {
  setAppError,
  setAppNotification,
  setAppTheme,
  setAppThemeConfig,
  toggleReportIssueModal,
  toggleUpgradeToProModal,
}
