import styled from 'styled-components'

export const Container = styled.div`
  .title {
    font-weight: 500;
  }

  .team-select {
    height: 46px;
    width: 100%;
    margin-bottom: 18px;
  }

  .license-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 10px 0px;

    .license-switch {
      display: flex;
      gap: 12px;
    }

    .text {
      color: ${(props) => props.theme.colors.gray8};
      margin: 0px;
    }
  }

  .info-text {
    color: ${(props) => props.theme.colors.gray8};
    font-size: 12px;
    margin: 10px 0px 0px;
  }
`
