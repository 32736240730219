import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Spin, Tooltip } from 'antd'
import { PlusOutlined, UserOutlined, EditOutlined } from '@ant-design/icons'
import { ContactCard24Regular } from '@fluentui/react-icons'
import Button from '@/components/Button'
import { setEditAccountModalOpen } from '@/store/accounts/actions'
import { Container } from './styles'

const AccountOwnerAccountsTable = () => {
  const dispatch = useDispatch()

  const { accountsData, isLoading: isAccountsLoading } = useSelector((state) => state.accounts)

  const handleOpenEditAccountModal = (evt, account) => {
    evt.preventDefault()
    dispatch(setEditAccountModalOpen(true, account))
  }

  return (
    <Container className="account-owner-accounts-table-container">
      <div className="content">
        <div className="header">
          <h4 className="title">Select your account</h4>
        </div>

        <div className="accounts-container">
          {isAccountsLoading ? (
            <div className="loading-container">
              <Spin size="large" />
              <p>Loading accounts...</p>
            </div>
          ) : (
            accountsData?.results?.map((a) => (
              <Link key={a?.id} to={`/accounts/${a?.id}`}>
                <div className="account-item" key={a?.id}>
                  <div className="account-image-container">
                    {a?.image_url && <img className="account-image" src={a?.image_url} alt={a?.name} />}
                  </div>

                  <div className="info">
                    <h4 className="title">{a?.name}</h4>

                    <div className="stats">
                      <Tooltip title="Total members">
                        <p className="stat-item">
                          <UserOutlined className="icon" /> {a?.users_count}
                        </p>
                      </Tooltip>

                      <Tooltip title="Assigned licenses">
                        <p className="stat-item">
                          <ContactCard24Regular className="icon" />
                          {a?.licenses_summary?.assigned}/{a?.licenses_summary?.total}
                        </p>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="actions">
                    <Tooltip title="Edit account settings">
                      <Button
                        type="text"
                        icon={<EditOutlined className="icon" />}
                        onClick={(evt) => handleOpenEditAccountModal(evt, a)}
                      />
                    </Tooltip>
                  </div>
                </div>
              </Link>
            ))
          )}

          <div className="divider">
            <div className="line" />
            <h4 className="text">or</h4>
            <div className="line" />
          </div>

          <Link to={`/create-account`}>
            <div className="account-item new">
              <div className="account-image-container">
                <PlusOutlined className="icon" />
              </div>

              <h4 className="title">Create new account</h4>
            </div>
          </Link>
        </div>
      </div>
    </Container>
  )
}
export default AccountOwnerAccountsTable
