const ignoreEmailDomains = [
  '01gmail.com',
  '126.com',
  '163.com',
  '5669gmail.com',
  '8shield.net',
  'abv.bg',
  'aol.com',
  'aramask.com',
  'bk.ru',
  'bodeem.com',
  'byorby.com',
  'cazlg.com',
  'centrum.cz',
  'comcast.net',
  'cwmxc.com',
  'datawars.io',
  'duck.com',
  'email.com',
  'fgmail.com',
  'foxmail.com',
  'gamil.com',
  'gmai.com',
  'gmail.cim',
  'gmail.cm',
  'gmail.co',
  'gmail.com',
  'gmail.com1',
  'gmail.com3',
  'gmail.come',
  'gmail.comk',
  'gmail.comm',
  'gmail.con',
  'gmail.coom',
  'gmail.cpm',
  'gmail.ocm',
  'gmail.xom',
  'gmailbox.org',
  'gmaill.com',
  'gmil.com',
  'gmx.at',
  'gmx.ch',
  'gmx.com',
  'gmx.de',
  'gmx.net',
  'gnail.com',
  'googlemail.com',
  'hohotmail.com',
  'hotmail.be',
  'hotmail.ca',
  'hotmail.co.uk',
  'hotmail.com',
  'hotmail.com.ar',
  'hotmail.com.tr',
  'hotmail.coma',
  'hotmail.de',
  'hotmail.es',
  'hotmail.fr',
  'hotmail.gr',
  'hotmail.it',
  'hotmail.nl',
  'hotmail.se',
  'hotmail.sg',
  'icloud.com',
  'inbox.ru',
  'interia.pl',
  'jalunaki.com',
  'jybra.com',
  'libero.it',
  'live.biu.ac.il',
  'live.ca',
  'live.cn',
  'live.co.uk',
  'live.co.za',
  'live.com',
  'live.com.ar',
  'live.com.mx',
  'live.com.pt',
  'live.de',
  'live.fr',
  'live.hk',
  'live.in',
  'live.it',
  'live.nl',
  'live.no',
  'live.se',
  'mac.com',
  'mail.com',
  'mail.ru',
  'mcenb.com',
  'me.com',
  'mozmail.com',
  'msn.com',
  'myyahoo.com',
  'naver.com',
  'netscape.net',
  'o2.pl',
  'omeie.com',
  'op.pl',
  'outlook.be',
  'outlook.co.th',
  'outlook.com',
  'outlook.com.au',
  'outlook.com.br',
  'outlook.com.tr',
  'outlook.coml',
  'outlook.de',
  'outlook.es',
  'outlook.fr',
  'outlook.in',
  'outlook.it',
  'outlook.jp',
  'outlook.ph',
  'outlook.sa',
  'outlook.sg',
  'outlook.sk',
  'outook.com',
  'passmail.net',
  'pm.me',
  'posteo.de',
  'postoffice-b.de',
  'pronton.me',
  'proton.me',
  'protonmail.ch',
  'protonmail.com',
  'protonmail.me',
  'qgmail.com',
  'qq.com',
  'remoter.us',
  'rocketmail.com',
  'saul.pw',
  'seznam.cz',
  'simplelogin.com',
  'sina.com',
  'tec.mx',
  'tuta.io',
  'tutamail.com',
  'tutanota.com',
  'ukr.net',
  'web.de',
  'windowslive.com',
  'wp.pl',
  'ya.ru',
  'yahoo.ca',
  'yahoo.co.id',
  'yahoo.co.in',
  'yahoo.co.jp',
  'yahoo.co.uk',
  'yahoo.com',
  'yahoo.com.ar',
  'yahoo.com.au',
  'yahoo.com.br',
  'yahoo.com.hk',
  'yahoo.com.mx',
  'yahoo.com.my',
  'yahoo.com.sg',
  'yahoo.com.tr',
  'yahoo.de',
  'yahoo.dk',
  'yahoo.es',
  'yahoo.fr',
  'yahoo.gr',
  'yahoo.in',
  'yahoo.it',
  'yahoo.net',
  'yahoo.pl',
  'yahoo.se',
  'yahooinc.com',
  'yandex.com',
  'yandex.com',
  'yandex.ru',
  'yeah.net',
  'ymail.com',
  'zslsz.com',
]

export default ignoreEmailDomains
