import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 14px 0px;

  .editor-container {
    width: 100%;
    position: relative;
    display: flex;
    gap: 16px;

    .profile-picture {
      margin: 0px;
    }

    .send-button {
      color: ${(props) => props.theme.colors.gray8};
      position: absolute;
      right: 16px;
      bottom: 10px;
    }
  }

  .timeline-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    animation: 1s ${fadeInAnimation};
  }

  @media ${device.mdDown} {
    .editor-container {
      gap: 8px;
    }
  }
`
