import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  .name {
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0px;

    ${(props) =>
      props.isTeam &&
      css`
        text-transform: capitalize;
      `}
  }

  ${(props) =>
    props.size === 'xsmall' &&
    css`
      gap: 10px;

      .name {
        font-size: 13px;
      }
    `}

  @media ${device.mdDown} {
    gap: 8px;

    .name {
      font-size: 12px;
    }
  }
`
