import { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MDXEditor, BoldItalicUnderlineToggles, toolbarPlugin } from '@mdxeditor/editor'
import { Tooltip } from 'antd'
import { Send24Filled } from '@fluentui/react-icons'
import { capitalize } from '@/utils/functions'
import { showToast } from '@/utils/toast'
import Button from '@/components/Button'
import ProfilePicture from '@/components/ProfilePicture'
import PlaygroundEvent from './components/PlaygroundEvent'
import { createComment } from '@/store/playgrounds/actions'
import { Container } from './styles'

const PlaygroundComments = ({ user, playgroundCopyId, versionsAnalytics }) => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const { isLoading } = useSelector((state) => state.playgrounds)

  const mdxEditorRef = useRef(null)
  const [contentMd, setContentMd] = useState('')

  const onCommentCreated = () => {
    showToast('Comment created successfully')
    setContentMd('')
    mdxEditorRef.current?.setMarkdown('')
  }

  const handleCreateComment = () => {
    dispatch(
      createComment(
        playgroundCopyId,
        { content_md: mdxEditorRef.current?.getMarkdown(), user_id: user?.id },
        onCommentCreated,
      ),
    )
  }

  return (
    <Container className="playground-comments">
      <div className="editor-container">
        <ProfilePicture
          src={userProfile?.avatar_url}
          alt={capitalize(userProfile?.first_name)}
          size="large"
          defaultPicture="letter"
          disableTooltip
        />

        <MDXEditor
          ref={mdxEditorRef}
          contentEditableClassName="editor-content"
          markdown={''}
          placeholder="Write a comment..."
          plugins={[
            toolbarPlugin({
              toolbarContents: () => <BoldItalicUnderlineToggles />,
            }),
          ]}
          onChange={(value) => setContentMd(value)}
        />

        <Tooltip title={contentMd && 'Send comment'}>
          <Button
            className="send-button"
            type="text"
            icon={<Send24Filled />}
            onClick={handleCreateComment}
            disabled={!contentMd}
            loading={isLoading}
          />
        </Tooltip>
      </div>

      <div className="timeline-container">
        {[...versionsAnalytics.timeline]?.reverse()?.map((event, i) => (
          <PlaygroundEvent user={user} playgroundCopyId={playgroundCopyId} event={event} key={i} />
        ))}
      </div>
    </Container>
  )
}

export default PlaygroundComments
