import { useState } from 'react'
import { useSelector } from 'react-redux'
import { InstantSearch, SearchBox, Configure, useInstantSearch, useConfigure } from 'react-instantsearch'
import { SearchOutlined } from '@ant-design/icons'
import { CollectionsAdd24Regular } from '@fluentui/react-icons'
import { getAlgoliaSearchClient } from '@/utils/algolia'
import searchParameters from './searchParameters'
import Results from './components/Results'
import { Container } from './styles'

const AutoGradedProjectsContent = ({ goToProjectsTab }) => {
  const { results, status, indexUiState } = useInstantSearch()

  const isLoading = !results?.params || status === 'loading' || status === 'stalled'
  const hasResults = !!results?.hits?.length || !!indexUiState?.query
  const currentSearchParameters = indexUiState?.configure
  const { refine } = useConfigure({ ...currentSearchParameters })

  const [page, setPage] = useState(1)

  return (
    <>
      {(!results?.params || hasResults) && (
        <div className="main-content">
          <div className="header">
            <div className="search-box">
              <SearchBox placeholder="Search projects here..." />
              <SearchOutlined className="icon" />
            </div>
          </div>

          <div className="body">
            <div className="content">
              <Results
                results={results}
                status={status}
                indexUiState={indexUiState}
                refine={refine}
                page={page}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      )}

      {!isLoading && (
        <div className={`empty-state ${hasResults ? 'small' : ''}`}>
          <div className="empty-state-content">
            <div className="header">
              <div className="container">
                <div className="title-container">
                  <CollectionsAdd24Regular className="icon" />

                  <h4 className="title">
                    Custom projects featuring auto-grading and automated tests are in <span className="beta">Beta</span>
                  </h4>
                </div>

                {hasResults ? (
                  <>
                    <p className="text">
                      If you're interested in being one of the first to try it, email us at:{' '}
                      <a
                        className="link"
                        href="mailto:support@datawars.io?subject=Info about DataWars custom auto-graded projects"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@datawars.io
                      </a>
                      .
                    </p>
                  </>
                ) : (
                  <>
                    <p className="text">
                      With auto-graded projects you can define your own automated tests that are run against your
                      users/students' submissions to validate their score.
                    </p>

                    <p className="text">
                      We're currently working on the platform that will allow you to define automated tests for your
                      projects. If you're interested in being one of the first to try it, email us at:{' '}
                      <a
                        className="link"
                        href="mailto:support@datawars.io?subject=Info about DataWars custom auto-graded projects"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@datawars.io
                      </a>
                      . Please keep in mind that this is a Pro feature.
                    </p>

                    <p className="text">
                      In the meantime, all of DataWars projects support auto-grading and you can assign them today.{' '}
                      <a className="link" onClick={goToProjectsTab}>
                        See our catalog
                      </a>
                      .
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const AutoGradedProjectsTab = ({ goToProjectsTab }) => {
  const { currentAccount } = useSelector((state) => state.accounts)
  const customTracks =
    currentAccount?.custom_tracks?.map((id) => `skill_track.id:"${id}"`)?.join(' AND ') || 'skill_track.id:"NO"'

  const algoliaSearchClient = getAlgoliaSearchClient()

  const auxSearchParameters = {
    ...searchParameters,
    filters: `${searchParameters.filters} ${`AND (${customTracks})`}`.trim(),
  }

  return (
    <Container className="auto-graded-projects-tab">
      <InstantSearch searchClient={algoliaSearchClient} indexName={import.meta.env.REACT_APP_ALGOLIA_MODULES_INDEX}>
        <Configure {...auxSearchParameters} />

        <AutoGradedProjectsContent goToProjectsTab={goToProjectsTab} />
      </InstantSearch>
    </Container>
  )
}

export default AutoGradedProjectsTab
