import { useSelector } from 'react-redux'
import { Tooltip, Avatar } from 'antd'
import { Star24Filled } from '@fluentui/react-icons'
import { capitalize } from '@/utils/functions'
import DefaultAvatar from '@/assets/images/default-avatar.png'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import BasicBadge from '@/assets/images/plans/basic-badge.png'
import { Container } from './styles'

const ProfilePicture = ({
  className = '',
  src,
  alt,
  isPro,
  isTeams,
  isOwner,
  defaultPicture,
  disableTooltip,
  size,
}) => {
  const { themeConfig } = useSelector((state) => state.app)

  const colors = [
    themeConfig?.colors?.purple3,
    themeConfig?.colors?.geekblue3,
    themeConfig?.colors?.cyan2,
    themeConfig?.colors?.magenta3,
  ]
  const letter = capitalize(alt?.[0])
  const defaultColor = colors[alt?.length % colors.length || 0]

  return (
    <Tooltip title={!disableTooltip && !isTeams && `${isPro ? 'PRO' : 'Basic'} user`} placement="left">
      <Container className={`profile-picture ${className}`} size={size} isTeams={isTeams} isPro={isPro}>
        <span className="image-container">
          {defaultPicture === 'letter' && !src ? (
            <Avatar className="avatar" style={{ backgroundColor: defaultColor }} alt={alt}>
              {letter}
            </Avatar>
          ) : (
            <Avatar className="avatar" src={src || DefaultAvatar} alt={alt} />
          )}
        </span>

        {isOwner && (
          <Tooltip title={'Account Owner'} placement="right">
            <Star24Filled className="icon owner-badge" />
          </Tooltip>
        )}

        {isPro !== undefined &&
          (isPro ? (
            <img className="icon pro-badge" src={ProBadge} alt="PRO user" />
          ) : (
            <img className="icon basic-badge" src={BasicBadge} alt="Basic user" />
          ))}
      </Container>
    </Tooltip>
  )
}

export default ProfilePicture
