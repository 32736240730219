import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  > .main-content {
    height: 100%;
    width: 100%;
    overflow: auto;
    animation: 1s ${fadeInAnimation};

    .empty-state {
      height: 100%;
      background-color: ${(props) => props.theme.colors.gray1};

      .empty-state-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 0px 60px;

        > .header {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          text-align: center;
          flex: 1 0 50%;
          padding: 24px 36px;

          .container {
            .icon {
              font-size: 24px;
              color: ${(props) => props.theme.colors.geekblue5};
              margin-bottom: 10px;
            }

            .title {
              margin: 0px;
              line-height: 28px;
              margin-bottom: 0px;
            }

            > button {
              margin-top: 18px;
            }
          }
        }

        .teams-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          gap: 16px;
          flex: 1 0 50%;
          padding: 24px;

          > .row {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 22px;
          }

          .team-card {
            background-color: ${(props) => props.theme.colors.gray1};
            border-radius: 10px;
            padding: 16px;
          }
        }
      }
    }
  }
`
