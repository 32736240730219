import dayjs from 'dayjs'
import { Tooltip, Checkbox, Radio, Button as AntdButton } from 'antd'
import { BulbOutlined, EyeOutlined } from '@ant-design/icons'
import Button from '@/components/Button'
import HTMLBlock from '@/components/HTMLBlock'
import ActivityReactions from '@/components/Activity/components/ActivityReactions'

const MultipleChoiceActivity = ({ activity }) => {
  const isCheckbox = activity?.widget === 'checkbox'

  const answer = isCheckbox
    ? activity?.user_status?.submitted_answer?.selected_answer_ids || []
    : activity?.user_status?.submitted_answer?.selected_answer_ids?.[0]

  const isActivitySubmitted = !!activity.user_status
  const answerSubmittedAt = activity.user_status?.submitted_answer_at
  const isActivityPassed = activity.user_status?.is_passed

  const solutionRequestedAt = activity.user_status?.solution_requested_at
  const isSolutionRequestedBeforeSubmission = dayjs(solutionRequestedAt).isBefore(dayjs(answerSubmittedAt))

  const hintRequestedAt = activity.user_status?.hint_requested_at
  const isHintRequestedBeforeSubmission = dayjs(hintRequestedAt).isBefore(dayjs(answerSubmittedAt))

  const options = activity.answers.map((a) => ({
    label: (
      <div
        id={`answer-${a.id}`}
        className={a?.is_correct ? 'correct' : 'incorrect'}
        dangerouslySetInnerHTML={{
          __html: a.content_html,
        }}
      />
    ),
    value: a.id,
  }))

  const activityActions = (
    <div className="activity-actions">
      {isHintRequestedBeforeSubmission && (
        <Tooltip title={'Hint was requested'}>
          <AntdButton className="hint-btn" shape="circle" size="small" icon={<BulbOutlined />} disabled />
        </Tooltip>
      )}

      {activity?.has_solution && isSolutionRequestedBeforeSubmission && (
        <Tooltip title={'Solution was requested'}>
          <AntdButton className="solution-btn" shape="circle" size="small" icon={<EyeOutlined />} disabled />
        </Tooltip>
      )}
    </div>
  )

  return (
    <>
      <div
        id={`activity-${activity.id}`}
        className={`activity-container ${isCheckbox ? 'multiple-choice-activity' : 'single-choice-activity'}`}
      >
        <div className="activity-content">
          <div className="activity-body">
            <span className="activity-number">{(activity?.order || 0) + 1}</span>

            {activity?.title_html && (
              <div className="title-container" dangerouslySetInnerHTML={{ __html: activity?.title_html }} />
            )}
          </div>

          <HTMLBlock content={activity?.content_html} className={'activity-content-markdown'} />
        </div>

        {isActivitySubmitted && (
          <div className="submitted-box">
            <p className="text">Submitted answer:</p>

            {isCheckbox ? (
              <Checkbox.Group className="activity-answers-group" options={options} value={answer} disabled />
            ) : (
              <Radio.Group className="activity-answers-group" options={options} value={answer} disabled />
            )}
          </div>
        )}
      </div>

      <div className="review-status-block">
        {isActivitySubmitted ? (
          <Button type="dashed" className={`activity-status-btn ${isActivityPassed ? 'correct' : 'incorrect'}`}>
            {isActivityPassed ? 'Correct!' : 'Incorrect'}
          </Button>
        ) : (
          <Button type="dashed" className={'activity-status-btn not-actioned'}>
            Not actioned
          </Button>
        )}

        {activityActions}

        {isActivitySubmitted && <ActivityReactions activity={activity} />}
      </div>
    </>
  )
}

export default MultipleChoiceActivity
