import styled from 'styled-components'

export const Container = styled.div`
  .emails-input {
    width: 100%;
    position: relative;

    .ant-select-selector {
      height: 110px;
      overflow: auto;
      padding: 8px 20px 8px 12px;

      .ant-select-selection-overflow {
        align-self: flex-start;
        gap: 5px;

        .ant-select-selection-overflow-item {
          &:first-child.ant-select-selection-overflow-item-suffix {
            height: 24px;
            margin-left: -10px;
          }
        }
      }

      .ant-select-selection-placeholder {
        font-size: 14px;
        align-self: flex-start;
        top: 20px;
      }
    }

    .ant-select-clear {
      top: 8px;
      right: 20px;
      margin: 0px;
    }

    .email-tag {
      max-width: 330px;
      color: ${(props) => props.theme.colors.gray10};
      background-color: ${(props) => props.theme.colors.gray4};
      border: 1px solid ${(props) => props.theme.colors.gray4};
      border-radius: 5px;
      display: flex;
      gap: 5px;
      overflow: auto;
      padding: 3px 8px;
      margin: 0px;

      .anticon-close {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 16px;
        margin: 0px;
      }

      &.ant-tag-warning {
        background-color: ${(props) => props.theme.colors.gold1};
        color: ${(props) => props.theme.colors.gold6};
        border: 1px solid ${(props) => props.theme.colors.gold6};

        .anticon-close {
          color: ${(props) => props.theme.colors.gold6};
        }
      }

      &.ant-tag-error {
        background-color: ${(props) => props.theme.colors.red1};
        color: ${(props) => props.theme.colors.red5};
        border: 1px solid ${(props) => props.theme.colors.red5};

        .anticon-close {
          color: ${(props) => props.theme.colors.red5};
        }
      }
    }
  }
`
