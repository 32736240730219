import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  &.is-loading {
    max-width: 800px;
    width: 100%;

    .ant-skeleton {
      margin: 0px;

      .ant-skeleton-paragraph {
        margin: 0px;

        li {
          width: 100% !important;
        }
      }
    }
  }

  > .module-image {
    width: 100px;
    opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)};
    object-fit: cover;
    pointer-events: none;
  }

  > .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .actions {
      display: flex;
      align-items: center;
      gap: 10px;

      .back-button {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
      }
    }

    .pre-title {
      display: flex;
      align-items: center;
      gap: 10px;

      .skill-track-name {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 12px;
        line-height: 16px;
        margin: 0px;
      }
    }

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0px;
    }

    .tags-container {
      max-width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      overflow: auto;
    }

    .tag {
      height: 22px;
      background-color: ${(props) => props.theme.colors.base.gray4};
      color: ${(props) => props.theme.colors.base.gray8};
      text-transform: capitalize;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 6px;
      font-size: 11px;
      border: none;
      border-radius: 20px;
      margin: 0px;

      > img {
        width: initial;
      }

      &.module-type {
        background-color: ${(props) => props.theme.colors.base.gray4};
        color: ${(props) => props.theme.colors.base.gray8};

        > svg,
        > svg path {
          fill: ${(props) => props.theme.colors.base.gray8};
          width: 14px;
        }
      }

      &.is-beta {
        color: ${(props) => props.theme.colors.base.gray1};
        background-color: ${(props) => props.theme.colors.base.volcano6};
      }

      &.difficulty-easy {
        color: ${(props) => props.theme.colors.base.gray9};
        background-color: ${(props) => props.theme.colors.base.green2};
      }

      &.difficulty-medium {
        color: ${(props) => props.theme.colors.base.gray9};
        background-color: ${(props) => props.theme.colors.base.gold3};
      }

      &.difficulty-hard {
        color: ${(props) => props.theme.colors.base.gray9};
        background-color: ${(props) => props.theme.colors.base.red2};
      }
    }
  }
`
