import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Tabs } from 'antd'
import TeamsDropdown from '../TeamsDropdown'
import LeaderboardTable from '../LeaderboardTable'
import ProjectsAnalyticsTable from '../ProjectsAnalyticsTable'
import PlaygroundsAnalyticsTable from '../PlaygroundsAnalyticsTable'
import { setAnalyticsTab, setUserSearchText } from '@/store/accounts/actions'
import { Container } from './styles'

const AnalyticsTab = () => {
  const dispatch = useDispatch()
  let [searchParams, setSearchParams] = useSearchParams()

  const { selectedAnalyticsTab } = useSelector((state) => state.accounts)

  const tab = searchParams.get('tab')

  const tabItems = [
    {
      key: 'leaderboard',
      label: 'Leaderboard',
      children: (
        <div className="tab-content">
          <LeaderboardTable size="middle" />
        </div>
      ),
    },
    {
      key: 'projects',
      label: 'DataWars projects',
      children: (
        <div className="tab-content">
          <ProjectsAnalyticsTable />
        </div>
      ),
    },
    {
      key: 'customProjects',
      label: 'Custom projects',
      children: (
        <div className="tab-content">
          <PlaygroundsAnalyticsTable />
        </div>
      ),
    },
  ]

  const handleChangeTab = (tab) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), tab })
  }

  useEffect(() => {
    dispatch(setUserSearchText(''))

    const isValidTab = ['leaderboard', 'projects', 'customProjects'].includes(tab)

    if (tab && tab !== selectedAnalyticsTab && isValidTab) {
      dispatch(setAnalyticsTab(tab))
      return
    }

    if (!tab || !isValidTab) {
      dispatch(setAnalyticsTab('leaderboard'))
    }
  }, [tab])

  useEffect(() => {
    return () => {
      dispatch(setUserSearchText(''))
    }
  }, [])

  return (
    <Container className="analytics-tab">
      <div className="header-container">
        <div className="info">
          <div className="title-container">
            <h4 className="title">
              Analytics <span className="divider">/</span>
            </h4>{' '}
            <TeamsDropdown showAllOption showNotInTeamsOption />
          </div>
        </div>
      </div>

      <div className="content">
        <Tabs activeKey={selectedAnalyticsTab} items={tabItems} onChange={handleChangeTab} />
      </div>
    </Container>
  )
}

export default AnalyticsTab
