import { Tag, Skeleton } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import FadeIn from '@/components/FadeIn'
import Button from '@/components/Button'
import LearnIcon from '@/assets/images/learn.svg?react'
import { Container } from './styles'

const ModuleInfo = ({ module, isLoading, handleBack }) => {
  const isBeta = ['beta'].includes(module?.status)

  const renderTags = () => {
    return (
      <div className="tags-container">
        {isBeta && <Tag className="tag is-beta">Beta</Tag>}

        {module?.module_type === 'learn' && (
          <Tag className="tag module-type">
            <LearnIcon />
            Learn
          </Tag>
        )}

        {module?.tags?.map((t) => (
          <Tag key={t?.id} className="tag">
            {t?.name}
          </Tag>
        ))}
      </div>
    )
  }

  if (isLoading)
    return (
      <Container className="module-info is-loading">
        <div className="content">
          <div className="actions">
            {handleBack && (
              <Button className="back-button" type="text" size="small" onClick={handleBack}>
                <ArrowLeftOutlined /> View all
              </Button>
            )}
          </div>

          <Skeleton active title={false} paragraph={{ rows: 3 }} />
        </div>
      </Container>
    )

  return (
    <FadeIn>
      <Container className="module-info">
        {renderModuleImage(module)}

        <div className="content">
          <div className="actions">
            {handleBack && (
              <Button className="back-button" type="text" size="small" onClick={handleBack}>
                <ArrowLeftOutlined /> View all
              </Button>
            )}
          </div>

          <div className="pre-title">
            <Tag className={`tag difficulty-${module?.difficulty}`}>{module?.difficulty}</Tag>
            <p className="skill-track-name">{module?.skill_track?.name}</p>
          </div>

          <h3 className="title">{module?.name}</h3>

          {renderTags()}
        </div>
      </Container>
    </FadeIn>
  )
}

export default ModuleInfo
