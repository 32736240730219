import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  max-width: 480px;
  width: 100% !important;

  .ant-modal-content {
    padding: 0px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 32px;

      > .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin: 30px 0 20px;

        .icon {
          width: 35px;
          height: 35px;
          color: ${(props) => props.theme.colors.geekblue5};
          font-size: 35px;

          &.success {
            color: ${(props) => props.theme.colors.cyan5};
          }

          &.error {
            color: ${(props) => props.theme.colors.red6};
          }
        }

        .title {
          max-width: 290px;
          font-weight: 400;
          text-align: center;
          margin: 0px;
        }

        .text {
          max-width: 300px;
          color: ${(props) => props.theme.colors.gray8};
          text-align: center;
          margin: 0px;
        }
      }

      > .content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0px 40px;

        .divider {
          width: 100%;
          border-top: 1px solid ${(props) => props.theme.colors.gray4};
        }

        .section {
          .section-title {
            font-weight: 500;
          }
        }

        .team-select {
          height: 46px;
          width: 100%;
        }

        .members-table {
          max-height: 220px;
          overflow: auto;

          .ant-table-cell-row-hover {
            background: initial;
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 12px;
      }
    }
  }

  .ant-modal-close {
    height: 40px;
    width: 40px;
    background-color: ${(props) => props.theme.colors.gray4};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 2px;

    .ant-modal-close-x {
      line-height: inherit;

      svg {
        color: ${(props) => props.theme.colors.gray10};
        font-size: 18px;
      }
    }
  }
`
