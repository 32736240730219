import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Button from '@/components/Button'
import CreateAccountForm from '../CreateAccountForm'
import BusinessIcon from '@/assets/images/business.svg'
import EducationIcon from '@/assets/images/education.svg'
import { Container } from './styles'

const AccountCreationContent = () => {
  let [searchParams] = useSearchParams()

  const urlOrgType = ['education', 'business'].includes(searchParams.get('org_type')) && searchParams.get('org_type')

  const [orgType, setOrgType] = useState(urlOrgType)
  const [showForm, setShowForm] = useState(!!urlOrgType)

  return (
    <Container id="account-creation-content">
      {showForm ? (
        <CreateAccountForm orgType={orgType} goToAccountTypeSelector={urlOrgType ? null : () => setShowForm(false)} />
      ) : (
        <Container id="account-type-selector">
          <div className="header">
            <h4 className="title">Create your free organization</h4>

            <h5 className="subtitle">Select your organization type</h5>
          </div>

          <div className="org-type-container">
            <div
              className={`org-type ${orgType === 'education' ? 'is-selected' : ''}`}
              onClick={() => setOrgType('education')}
            >
              <img src={EducationIcon} alt="Education" />
              <p className="type-name">Education</p>
              <p className="text">
                For your School, University
                <br />
                or private classes.
              </p>
            </div>

            <div
              className={`org-type ${orgType === 'business' ? 'is-selected' : ''}`}
              onClick={() => setOrgType('business')}
            >
              <img src={BusinessIcon} alt="Business" />
              <p className="type-name">Business</p>
              <p className="text">
                For your Company or
                <br />
                Work team.
              </p>
            </div>
          </div>

          <div className="actions">
            <span />

            <Button type="primary" disabled={!orgType} onClick={() => setShowForm(true)}>
              Next
            </Button>
          </div>
        </Container>
      )}
    </Container>
  )
}

export default AccountCreationContent
