import { SET_ACTIVITIES_LOADING } from '@/store/activities'
import { postActivityReaction, deleteActivityReaction } from '@/services/activities'
import { showToast } from '@/utils/toast'

const createActivityReaction = (activityId, data) => async (dispatch) => {
  try {
    dispatch(SET_ACTIVITIES_LOADING(true))

    await postActivityReaction(activityId, data)
  } catch (error) {
    const { message } = error
    showToast(message, 'error')
  } finally {
    dispatch(SET_ACTIVITIES_LOADING(false))
  }
}

const removeActivityReaction = (activityId, data) => async (dispatch) => {
  try {
    dispatch(SET_ACTIVITIES_LOADING(true))

    await deleteActivityReaction(activityId, data)
  } catch (error) {
    const { message } = error
    showToast(message, 'error')
  } finally {
    dispatch(SET_ACTIVITIES_LOADING(false))
  }
}

export { createActivityReaction, removeActivityReaction }
