import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  .teams-table {
    height: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    overflow: auto;

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;

        .ant-table {
          height: calc(100% - 60px);
        }

        .ant-pagination {
          justify-content: flex-start;
        }
      }
    }

    .ant-table-body {
      max-height: calc(100vh - 282px) !important;
      overflow: auto !important;
    }

    .team-item-container {
      min-width: 142px;
      width: 100%;
    }

    .members-container {
      > div {
        margin-right: -4px;
      }
    }

    .team-actions {
      display: flex;
      gap: 8px;
    }
  }

  @media ${device.lgDown} {
    .teams-table {
      .ant-table-body {
        max-height: calc(100vh - 346px) !important;
      }
    }
  }

  @media ${device.mdDown} {
    gap: 12px;

    .teams-table {
      .ant-table-body {
        max-height: calc(100vh - 304px) !important;

        .ant-table-cell {
          font-size: 12px;
          padding: 10px;
        }
      }
    }
  }
`
