import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  .filter-category {
    &:not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
      padding-bottom: 10px;
      margin-bottom: 20px;
    }

    .ant-collapse-header {
      padding: 0px;

      .ant-collapse-expand-icon {
        color: ${(props) => props.theme.colors.gray10};
      }
    }

    .filter-title {
      background-color: ${(props) => props.theme.colors.geekblue2};
      font-weight: 400;
      border-radius: 2px;
      display: inline-block;
      padding: 0px 4px;
      margin: 0px;
    }

    .ais-RefinementList-list {
      text-transform: capitalize;
      list-style: none;
      padding: 0px;

      .ais-RefinementList-label {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .ais-RefinementList-labelText {
          color: ${(props) => props.theme.colors.gray10};
          line-height: 20px;
          flex-grow: 1;
          margin: 0px 10px;
        }

        .ais-RefinementList-count {
          background-color: ${(props) => props.theme.colors.geekblue1};
          color: ${(props) => props.theme.colors.gray8};
          font-size: 12px;
          font-weight: 400;
          border-radius: 3px;
          padding: 1px 4px;
        }
      }
    }

    .ais-RefinementList-showMore--disabled {
      display: none;
    }
  }
`
