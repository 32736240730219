import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spin, Tooltip } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { EmojiAdd24Regular } from '@fluentui/react-icons'
import { capitalize } from '@/utils/functions'
import { showToast } from '@/utils/toast'
import activityReactions from '@/constants/activityReactions'
import { createActivityReaction, removeActivityReaction } from '@/store/activities/actions'
import { Container } from './styles'

const ActivityReactions = ({ activity }) => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const { currentAttempt } = useSelector((state) => state.accounts)
  const { isLoading } = useSelector((state) => state.activities)

  const userReactions = activity?.user_status?.reactions?.filter((r) => r?.user?.username === userProfile?.username)
  const otherReactions = activity?.user_status?.reactions?.filter((r) => r?.user?.username !== userProfile?.username)

  const [selectedReaction, setSelectedReaction] = useState()
  const [isReacting, setIsReacting] = useState(false)

  const handleReaction = async (reaction) => {
    if (!isReacting) return

    if (selectedReaction) {
      await dispatch(
        removeActivityReaction(activity.id, { module_attempt_id: currentAttempt, reaction: selectedReaction }),
      )
    }

    if (selectedReaction === reaction) {
      setSelectedReaction(null)
      showToast('Reaction removed from activity')
      return
    }

    await dispatch(createActivityReaction(activity.id, { module_attempt_id: currentAttempt, reaction }))
    setSelectedReaction(reaction)
    showToast('Reaction added to activity')
  }

  useEffect(() => {
    setSelectedReaction(userReactions?.[0]?.reaction)
  }, [activity])

  return (
    <Container className="activity-reactions">
      {!!otherReactions?.length && (
        <div className="other-reaction-container">
          {otherReactions?.map((reaction) => {
            const reactionData = activityReactions.find((r) => r.name === reaction.reaction)

            return (
              <Tooltip
                key={reaction.user.username}
                title={`${capitalize(reaction.user.first_name)} ${capitalize(reaction.user.last_name)} reacted`}
              >
                <img className="reaction-emoji" src={reactionData?.emoji} alt={reactionData?.name} />
              </Tooltip>
            )
          })}
        </div>
      )}

      <Tooltip title={!isReacting && (selectedReaction ? 'Your reaction' : 'React to this activity')}>
        <div
          className={`user-reactions-container ${isReacting ? 'is-reacting' : ''} ${selectedReaction ? 'has-reaction' : ''} `}
          onClick={() => setIsReacting(!isReacting)}
        >
          {isLoading ? (
            <Spin size="small" />
          ) : (
            <>
              {activityReactions.map((reactionData) => (
                <span
                  key={reactionData.name}
                  className={`activity-reaction ${selectedReaction === reactionData.name ? 'selected' : ''}`}
                  onClick={() => handleReaction(reactionData.name)}
                >
                  <img className="reaction-emoji" src={reactionData?.emoji} alt={reactionData.name} />
                </span>
              ))}

              {!selectedReaction && <EmojiAdd24Regular className="default-reaction" />}

              <RightOutlined className="close-button" onClick={() => setIsReacting(false)} />
            </>
          )}
        </div>
      </Tooltip>
    </Container>
  )
}

export default ActivityReactions
