import WelcomeCard from './components/WelcomeCard'
import LicensesCard from './components/LicensesCard'
import PendingInvitesCard from './components/PendingInvitesCard'
import TeamsCard from './components/TeamsCard'
import ActivitiesCard from './components/ActivitiesCard'
import MembersCard from './components/MembersCard'
import { Container } from './styles'

const DashboardTab = () => {
  return (
    <Container>
      <div className="main-content">
        <div className="col col-33 show-lg-down">
          <div className="row">
            <WelcomeCard />
            <MembersCard />
          </div>
        </div>

        <div className="col col-66">
          <div className="row no-grow">
            <WelcomeCard className="hide-lg-down" />
            <LicensesCard />
          </div>

          <PendingInvitesCard />

          <div className="row">
            <TeamsCard />
            <ActivitiesCard />
          </div>
        </div>

        <div className="col col-33 hide-lg-down">
          <MembersCard />
        </div>
      </div>
    </Container>
  )
}

export default DashboardTab
