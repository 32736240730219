import styled, { css, keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import MembersEmptyState from '@/assets/images/empty-state-members.png'
import MembersEmptyStateDark from '@/assets/images/empty-state-members-dark.png'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  overflow: auto;

  .table-header {
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 24px;

    .title {
      flex: 1 1 100%;
      margin: 0px;
    }

    .filters {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      flex: 1 1 100%;

      .user-search {
        max-width: 300px;
        width: 100%;

        .ant-input-search-button {
          cursor: default;

          &:hover {
            border-color: ${(props) => props.theme.colors.gray5};
          }

          > div {
            display: none;
          }
        }
      }
    }
  }

  .table-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;

    .fade-in-mask {
      width: 100%;
      height: 100%;
      display: flex;
    }

    .leaderboard-table {
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.colors.gray1};
      overflow: auto;

      .ant-spin-nested-loading {
        height: 100%;

        .ant-spin-container {
          height: 100%;

          .ant-table {
            height: calc(100% - 60px);
          }

          .ant-pagination {
            justify-content: flex-start;
          }
        }
      }

      .ant-table-body {
        max-height: calc(100vh - 420px) !important;
        overflow: auto !important;
      }

      .ant-table-cell {
        &.ranking-col {
          min-width: 72px;
          text-align: center;
        }

        &.name-col {
          max-width: 210px;
          width: 100%;
          overflow: hidden;
          padding: 12px 16px;
        }

        &.finished-activities-col {
          min-width: 102px;
        }
      }

      tbody td.ant-table-cell {
        &.ranking-col {
          min-width: 68px;
          font-weight: 700;
        }
      }
    }
  }

  .loading-container {
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;
  }

  .empty-state-container {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    animation: 1s ${fadeInAnimation};

    .empty-state-content {
      height: 100%;
      width: 100%;

      ${() =>
        localStorage.getItem('dw-theme') === 'dark'
          ? css`
              background: url(${MembersEmptyStateDark}) no-repeat center center;
            `
          : css`
              background: url(${MembersEmptyState}) no-repeat center center;
            `}

      > .header {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 24px 36px;

        .container {
          max-width: 460px;

          .title-container {
            max-width: 230px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;

            .icon {
              min-width: 24px;
              font-size: 24px;
              color: ${(props) => props.theme.colors.geekblue5};
            }

            .title {
              margin: 0px 0px 12px;
            }
          }

          .text {
            color: ${(props) => props.theme.colors.gray7};
            margin: 0px 0px 20px;
          }

          .link {
            color: ${(props) => props.theme.colors.gray7};
            text-decoration: underline;
          }

          > button {
            margin-top: 18px;
          }
        }
      }
    }
  }

  @media ${device.lgDown} {
    .table-container {
      .leaderboard-table {
        .ant-table-body {
          max-height: calc(100vh - 486px) !important;
        }
      }
    }
  }

  @media ${device.mdDown} {
    gap: 12px;

    .table-header {
      max-height: 90px;
      height: 100%;
      border-top: 1px solid ${(props) => props.theme.colors.gray4};
      padding: 0px 14px;

      .filters {
        flex-direction: column;

        .user-search {
          max-width: initial;
        }

        .actions {
          justify-content: flex-start;
        }
      }
    }

    .table-container {
      .leaderboard-table {
        .ant-table-header {
          .ant-table-cell {
            font-size: 12px;
            line-height: 16px;
            padding: 0px 14px;
          }
        }

        .ant-table-body {
          max-height: calc(100vh - 438px) !important;

          .ant-table-cell {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
`
