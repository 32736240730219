import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.gray3};
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  overflow: auto;

  .left-content,
  .right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;

    > .content {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > .title {
        max-width: 330px;
        color: ${(props) => props.theme.colors.base.gray1};
        font-weight: 700;
        text-align: left;
        margin: 0px;
        text-shadow: 0px 0px 32px ${(props) => props.theme.colors.base.gray13};
      }
    }
  }

  .left-content {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .right-content {
    .content {
      max-width: 420px;
    }
  }

  @media ${device.lgUp} {
    flex-direction: row;

    .left-content,
    .right-content {
      min-height: calc(100vh - 64px);
      padding: 60px 20px;
    }

    .left-content {
      width: 33%;
      background-position: top;
    }

    .right-content {
      width: 66%;
      overflow: auto;
    }
  }

  @media ${device.lgDown} {
    .left-content {
      > .content {
        > .title {
          font-size: 28px;
          line-height: 34px;
          text-align: center;
        }
      }
    }
  }
`
