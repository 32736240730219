import styled, { css, keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import { Modal as AntModal } from 'antd'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Modal = styled(AntModal)`
  max-height: calc(100% - 50px);
  max-width: 880px;
  width: 100% !important;
  top: 30px;
  padding: 0px 20px;

  .ant-modal-content {
    background-color: ${(props) => props.theme.colors.gray1} !important;
    padding: 24px;
  }

  .ant-modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;

    .container {
      min-height: 520px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      > .header {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        margin: 20px 50px 10px 20px;

        &.is-loading {
          flex-direction: column;
          gap: 0px;
        }

        > .image-container {
          min-width: 80px;
          max-width: 80px;
          min-height: 80px;
          max-height: 80px;
          background: linear-gradient(
            250deg,
            ${(props) => props.theme.colors.base.geekblue4} 0%,
            ${(props) => props.theme.colors.base.gray12} 100%
          );
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;

          .main-icon {
            width: 32px;
            height: 32px;
            color: ${(props) => props.theme.colors.base.gray1};
            font-size: 32px;
          }

          ${(props) =>
            props.playgroundBackgroundImage &&
            css`
              background-image: url(${(props) => props.playgroundBackgroundImage});
              background-size: cover;
              background-position: center;
              object-fit: cover;

              .main-icon {
                display: none;
              }
            `}
        }

        .info {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 4px;

          .title-container {
            display: flex;
            align-items: flex-start;
            gap: 8px;

            > .blob-container {
              margin-top: 4px;
            }

            .title {
              font-weight: 700;
              margin: 0px;
            }
          }

          .subtitle {
            display: flex;
            gap: 12px;
            margin: 0px;

            .status-tag {
              background-color: ${(props) => props.theme.colors.gray2};
              color: ${(props) => props.theme.colors.base.gray7};
              border: 1px solid ${(props) => props.theme.colors.gray5};
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 4px;
              font-size: 12px;
              border-radius: 5px;
              margin: 0px;

              svg {
                width: 14px;
                height: 14px;
              }

              &.not-submitted {
                background-color: ${(props) => props.theme.colors.gold1};
                color: ${(props) => props.theme.colors.base.gold6};
                border: 1px solid ${(props) => props.theme.colors.gold4};
              }

              &.submitted {
                background-color: ${(props) => props.theme.colors.geekblue1};
                color: ${(props) => props.theme.colors.base.geekblue5};
                border: 1px solid ${(props) => props.theme.colors.geekblue5};
              }
            }

            .member-info {
              border-left: 1px solid ${(props) => props.theme.colors.gray4};
              padding-left: 12px;

              > span {
                color: ${(props) => props.theme.colors.gray7};
                font-weight: 400;
              }
            }
          }
        }
      }

      .select-container {
        margin: 10px 20px;

        .attempt-select {
          width: 100%;
        }
      }

      > .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow: auto;

        .fade-in-mask {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        .section {
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding: 20px;

          &.playground-preview-section {
            height: 100%;
            background-color: ${(props) => props.theme.colors.geekblue1};
            overflow: auto;
            padding: 0px;

            .playground-preview {
              max-width: 840px;
              background: ${(props) => props.theme.colors.gray1};
              box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
              animation: 1s ${fadeInAnimation};
            }

            .loading-content {
              height: 100%;
              padding: 20px;
            }

            .no-preview-available {
              height: 100%;
              min-height: 300px;
              max-width: 500px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              text-align: center;
              padding: 20px;
              margin: 0 auto 20px;
              animation: 1s ${fadeInAnimation};

              .link {
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .generate-preview-block {
          background-color: ${(props) => props.theme.colors.cyan1};
          color: ${(props) => props.theme.colors.gray7};
          border: 1px solid ${(props) => props.theme.colors.cyan5};
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          gap: 20px;
          padding: 8px 16px;
          margin-bottom: 14px;
          animation: 1s ${fadeInAnimation};

          .info {
            display: flex;
            align-items: center;
            gap: 6px;
            font-size: 12px;
          }

          .link {
            color: ${(props) => props.theme.colors.gray10};

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .tabs-container {
        padding: 0px 20px;

        .tab-item {
          display: flex;
          gap: 4px;
          position: relative;
        }

        .section {
          padding: 0px;
        }
      }
    }
  }

  .ant-modal-close {
    height: 40px;
    width: 40px;
    background-color: ${(props) => props.theme.colors.gray4};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 2px;

    .ant-modal-close-x {
      line-height: inherit;

      svg {
        color: ${(props) => props.theme.colors.gray10};
        font-size: 18px;
      }
    }
  }

  @media ${device.mdDown} {
    .ant-modal-content {
      padding: 8px;
    }

    .ant-modal-body {
      .container {
        > .header {
          margin: 14px 20px 10px;

          .image-container {
            display: none;
          }
        }
      }
    }
  }
`
