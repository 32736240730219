import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams, useParams, Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Input, Select, Pagination, Spin, Empty } from 'antd'
import { CheckmarkCircle24Filled, PersonArrowRight24Regular } from '@fluentui/react-icons'
import { debounce } from 'lodash'
import { platformURL } from '@/helpers/env'
import FadeIn from '@/components/FadeIn'
import Button from '@/components/Button'
import MemberAvatar from '@/components/MemberAvatar'
import ModuleInfo from '../ModuleInfo'
import { fetchSkillTracks } from '@/store/skillTracks/actions'
import { fetchProjectsAnalytics, setUserSearchText, setProjectAnalyticsModalOpen } from '@/store/accounts/actions'
import { fetchModule, setAssignModalOpen } from '@/store/modules/actions'
import { Container } from './styles'

const ProjectsAnalyticsTable = () => {
  const dispatch = useDispatch()
  const { accountId } = useParams()

  let [searchParams, setSearchParams] = useSearchParams()
  const projectSearchParam = searchParams.get('project')
  const metricSearchParam = searchParams.get('metric')

  const { themeConfig, pageSize: defaultPageSize } = useSelector((state) => state.app)
  const {
    allUsers,
    currentAccount: account,
    projectsAnalytics,
    userSearchText,
    filteredTeam,
    selectedAnalyticsTab,
    isLoading: isAccountsLoading,
  } = useSelector((state) => state.accounts)
  const { currentModule: module, isLoading: isModulesIsLoading } = useSelector((state) => state.modules)
  const { items: skillTracks } = useSelector((state) => state.skillTracks)

  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedProject, setSelectedProject] = useState()
  const [selectedMetric, setSelectedMetric] = useState()
  const [selectedSkillTrack, setSelectedSkillTrack] = useState()
  const [uniqueProjects, setUniqueProjects] = useState([])
  const [projectSkillTracks, setProjectSkillTracks] = useState([])

  const projectURL = selectedProject ? `${platformURL}project/${selectedProject?.id}` : null

  const metrics = [
    { value: 'scoring', label: 'Progress', type: 'percentage' },
    { value: 'submitted_activities', label: 'Submitted activities', type: 'number' },
    { value: 'total_activities', label: 'Total activities', type: 'number' },
    { value: 'passed_activities', label: 'Passed activities', type: 'number' },
    { value: 'failed_activities', label: 'Failed activities', type: 'number' },
    { value: 'hints_requested', label: 'Hints requested', type: 'number' },
    { value: 'solutions_requested', label: 'Solutions requested', type: 'number' },
    { value: 'lab_running_time', label: 'Lab time (min)', type: 'duration' },
    { value: 'started_at', label: 'Attempt started at', type: 'date' },
    { value: 'finished_at', label: 'Attempt finished at', type: 'date' },
    { value: 'last_activity_at', label: 'Last activity at', type: 'date' },
    { value: 'number_of_attempts', label: 'Attempts', type: 'number' },
  ]

  const teamId = filteredTeam === 'all' ? undefined : filteredTeam === 'not-in-teams' ? 'None' : filteredTeam

  const getUserMetric = (metricName, metricRawValue) => {
    const metricType = metrics?.find((m) => m.value === metricName)?.type

    let metricValue
    let metricText
    let metricColors

    if (metricType === 'percentage') {
      metricValue = Math.round((metricRawValue || 0) * 100, 2)
      metricText = metricValue ? (
        <div className="metric-text">
          <span>{metricValue}%</span>
          {metricValue === 100 && <CheckmarkCircle24Filled className="check-icon" />}
        </div>
      ) : (
        ''
      )

      metricColors = [
        { minValue: 75, backgroundColor: themeConfig.colors.geekblue4 },
        { minValue: 50, backgroundColor: themeConfig.colors.geekblue3 },
        { minValue: 25, backgroundColor: themeConfig.colors.geekblue2 },
        { minValue: 1, backgroundColor: themeConfig.colors.geekblue1 },
        { minValue: 0, backgroundColor: themeConfig.colors.gray2 },
      ]
    }

    if (metricType === 'number') {
      metricValue = metricRawValue || null
      metricText = metricValue

      metricColors = [
        { minValue: 20, backgroundColor: themeConfig.colors.magenta4 },
        { minValue: 10, backgroundColor: themeConfig.colors.magenta3 },
        { minValue: 5, backgroundColor: themeConfig.colors.magenta2 },
        { minValue: 1, backgroundColor: themeConfig.colors.magenta1 },
        { minValue: 0, backgroundColor: themeConfig.colors.gray2 },
      ]
    }

    if (metricType === 'duration') {
      metricValue = parseInt(metricRawValue / 60)
      metricText = metricValue ? `${metricValue} min` : ''

      metricColors = [
        { minValue: 45, backgroundColor: themeConfig.colors.purple4 },
        { minValue: 30, backgroundColor: themeConfig.colors.purple3 },
        { minValue: 15, backgroundColor: themeConfig.colors.purple2 },
        { minValue: 1, backgroundColor: themeConfig.colors.purple1 },
        { minValue: 0, backgroundColor: themeConfig.colors.gray2 },
      ]
    }

    if (metricType === 'date') {
      metricValue = metricRawValue ? dayjs().diff(dayjs(metricRawValue), 'day') : null
      metricText = metricRawValue ? dayjs(metricRawValue).from() : null

      metricColors = [
        { minValue: 31, backgroundColor: themeConfig.colors.cyan4 },
        { minValue: 21, backgroundColor: themeConfig.colors.cyan3 },
        { minValue: 14, backgroundColor: themeConfig.colors.cyan2 },
        { minValue: 7, backgroundColor: themeConfig.colors.cyan1 },
        { minValue: null, backgroundColor: themeConfig.colors.gray2 },
      ]
    }

    const metricStyles = metricColors.find((c) => metricValue >= c.minValue)
    const hasValue = !!metricRawValue

    return [hasValue, metricText, metricStyles]
  }

  const renderMetric = (userAnalyticsData, metricName = selectedMetric, projectId = selectedProject?.id) => {
    const userId = userAnalyticsData?.user_id
    const projectAnalytics = userAnalyticsData?.modules?.find((data) => data?.module_id === projectId)
    const metricRawValue = projectAnalytics?.[metricName]

    const [hasValue, metricText, metricStyles] = getUserMetric(metricName, metricRawValue)
    const keyValue = `${userAnalyticsData}-${projectId}-${metricName}`

    return (
      <td
        key={keyValue}
        className="stat-block has-content"
        style={{ ...metricStyles }}
        onClick={() => dispatch(setProjectAnalyticsModalOpen({ userId, projectId }))}
      >
        {metricText}
      </td>
    )
  }

  const getSkillTracksByLearningArea = () => {
    const grouped = {}

    projectSkillTracks
      ?.filter((t) => {
        return t
      })
      ?.forEach((t) => {
        if (t?.learning_area?.name in grouped) {
          grouped[t?.learning_area?.name].push({ value: t?.id, label: t?.name })
          return
        }

        grouped[t?.learning_area?.name] = [{ value: t?.id, label: t?.name }]
      })

    return Object.keys(grouped).map((la) => ({
      label: la,
      options: grouped[la],
    }))
  }

  const handleSearchProjectAnalytics = (newPage, newPageSize, newSearch) => {
    setCurrentPage(newPage || 1)

    if (newPageSize) {
      setPageSize(newPageSize)
    }

    setUniqueProjects(null)

    dispatch(
      fetchProjectsAnalytics(account?.id, {
        search: userSearchText || newSearch,
        team_id: teamId,
        track_id: selectedSkillTrack,
        page_size: newPageSize || pageSize,
        page: newPage || 1,
      }),
    )
  }

  const handleTableChange = (page, pageSize) => {
    handleSearchProjectAnalytics(page, pageSize)
  }

  const handleUserSearch = useMemo(
    () =>
      debounce((evt) => {
        const search = evt?.target?.value?.toLowerCase()

        handleSearchProjectAnalytics(null, null, search)
      }, 300),
    [filteredTeam],
  )

  const handleGoBackToAllProjects = () => {
    setSelectedProject(null)

    if (projectSearchParam) {
      searchParams.delete('project')
      setSearchParams(searchParams, { replace: true })
    }
  }

  useEffect(() => {
    if (!module) return

    setSelectedProject(module)
  }, [module])

  useEffect(() => {
    if (!projectSearchParam) return

    dispatch(fetchModule(projectSearchParam))
  }, [projectSearchParam])

  useEffect(() => {
    if (!skillTracks) return

    let projectSkillTrackIds = uniqueProjects?.map((item) => item?.skillTrackId)
    projectSkillTrackIds = [...new Set(projectSkillTrackIds)]

    setProjectSkillTracks(skillTracks?.filter((t) => projectSkillTrackIds?.includes(t?.id)))
  }, [skillTracks])

  useEffect(() => {
    if (!projectsAnalytics) return

    if (!skillTracks) {
      dispatch(fetchSkillTracks())
    }

    let projects = projectsAnalytics?.results
      ?.map((item) => item?.modules)
      ?.flat()
      ?.map((item) =>
        JSON.stringify({
          id: item?.module_id,
          name: item?.module__name,
          skillTrackId: item?.module__skill__skill_track_id,
        }),
      )
    projects = [...new Set(projects)]?.map((s) => JSON.parse(s))
    setUniqueProjects(projects)
  }, [projectsAnalytics])

  useEffect(() => {
    if (!account) return

    if (selectedAnalyticsTab !== 'projects') {
      handleGoBackToAllProjects()
      return
    }

    handleSearchProjectAnalytics()
  }, [selectedAnalyticsTab, account, selectedSkillTrack, filteredTeam]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedProject && projectSearchParam !== selectedProject) {
      setSearchParams(
        { ...Object.fromEntries(searchParams.entries()), project: selectedProject?.id },
        { replace: true },
      )
    }
  }, [selectedProject])

  useEffect(() => {
    if (!selectedMetric) {
      const isMetricValid = metrics?.find((m) => m?.value === metricSearchParam)
      setSelectedMetric(isMetricValid ? metricSearchParam : metrics?.[0]?.value)
    }

    if (selectedMetric && metricSearchParam !== selectedMetric) {
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), metric: selectedMetric }, { replace: true })
    }
  }, [selectedMetric])

  useEffect(() => {
    return () => {
      setSelectedProject(null)
    }
  }, [])

  return (
    <Container className="projects-analytics-table-container" isProjectSelected={selectedProject}>
      {!isAccountsLoading && !!allUsers?.length && (
        <div className={`table-header ${selectedProject && 'is-selected'}`}>
          {selectedProject && (
            <div className="selected-module-content">
              <ModuleInfo
                module={module}
                isLoading={isModulesIsLoading || selectedProject?.name !== module?.name}
                handleBack={handleGoBackToAllProjects}
              />
            </div>
          )}
          <span />

          <div className="filters">
            <Input.Search
              className="user-search"
              value={userSearchText}
              onChange={(evt) => {
                dispatch(setUserSearchText(evt?.target?.value?.toLowerCase()))
                handleUserSearch(evt)
              }}
              allowClear
              placeholder="Search member..."
              loading={isAccountsLoading}
            />

            {selectedProject ? (
              module && (
                <div className="actions">
                  <Button
                    icon={<PersonArrowRight24Regular style={{ width: 16 }} />}
                    onClick={() => dispatch(setAssignModalOpen(module))}
                  >
                    Assign
                  </Button>

                  <Link to={projectURL} target="_blank">
                    <Button type="primary">Go to project</Button>
                  </Link>
                </div>
              )
            ) : (
              <div className="selectors-container">
                <Select
                  className="metric-select skill-tracks"
                  value={selectedSkillTrack}
                  onChange={setSelectedSkillTrack}
                  onClear={setSelectedSkillTrack}
                  options={getSkillTracksByLearningArea()}
                  // fieldNames={{ label: 'name', value: 'id' }}
                  virtual={false}
                  showSearch
                  allowClear
                  filterOption={(search, optionOrGroup) =>
                    optionOrGroup.label.toLowerCase().includes(search.toLowerCase())
                  }
                  placeholder="Filter by Skill track"
                  loading={!skillTracks || isAccountsLoading}
                  disabled={!skillTracks || isAccountsLoading}
                  notFoundContent={<span style={{ padding: 12 }}>No results</span>}
                  dropdownStyle={{ width: 'auto', maxWidth: 300 }}
                />

                <Select
                  className="metric-select metric"
                  value={selectedMetric}
                  onChange={setSelectedMetric}
                  options={metrics}
                  virtual={false}
                  placeholder="Metric"
                  loading={!projectsAnalytics || isAccountsLoading}
                  disabled={!projectsAnalytics || isAccountsLoading}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {isAccountsLoading || uniqueProjects === null ? (
        <div className="loading-container">
          <Spin size="large" />
          <p>Loading project analytics...</p>
        </div>
      ) : allUsers?.length ? (
        <div className="table-container">
          <FadeIn>
            {projectsAnalytics?.count ? (
              <>
                <table className="table table-header-rotated">
                  <div className={`overlap-top-left ${selectedProject ? 'sm' : ''}`} />

                  <thead>
                    <tr>
                      <th />

                      {selectedProject ? (
                        metrics?.map((m) => (
                          <th key={m?.value} className="normal">
                            <div className="head-item-container">
                              <div className="head-item-content">{m?.label}</div>
                            </div>
                          </th>
                        ))
                      ) : uniqueProjects?.length ? (
                        uniqueProjects?.map((p) => (
                          <th key={p?.id} className="rotated">
                            <div className="head-item-container">
                              <div className="head-item-content" onClick={() => setSelectedProject(p)}>
                                {p?.name}
                              </div>
                            </div>
                          </th>
                        ))
                      ) : (
                        <th className="rotated empty">
                          <div className="head-item-container">
                            <div className="head-item-content">No available projects</div>
                          </div>
                        </th>
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {projectsAnalytics?.results?.map((userAnalyticsData) => {
                      const userData = allUsers?.find((userData) => userData?.user?.id === userAnalyticsData?.user_id)

                      return (
                        <tr key={userAnalyticsData?.user_id}>
                          <th className="row-header">
                            <MemberAvatar
                              lastName={userData?.user?.last_name}
                              firstName={userData?.user?.first_name}
                              avatarUrl={userData?.user?.avatar_url}
                              showName
                              isOwner={userData?.role === 'owner'}
                            />
                          </th>

                          {selectedProject ? (
                            metrics?.map((metric) => renderMetric(userAnalyticsData, metric?.value))
                          ) : uniqueProjects?.length ? (
                            uniqueProjects?.map((p) => renderMetric(userAnalyticsData, undefined, p?.id))
                          ) : (
                            <td className="stat-block"></td>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>

                <div className="pagination-container">
                  <Pagination
                    current={currentPage}
                    onChange={handleTableChange}
                    total={projectsAnalytics?.count}
                    pageSize={pageSize}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} members`}
                  />
                </div>
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No members found" />
            )}
          </FadeIn>
        </div>
      ) : (
        <div className="empty-state-container">
          <div className="empty-state-content">
            <div className="header">
              <div className="container">
                <div className="title-container">
                  <PersonArrowRight24Regular className="icon" />

                  <h4 className="title">DataWars projects</h4>
                </div>

                <p className="text">Projects from DataWars' catalog will show up here.</p>

                <Link className="link" to={`/accounts/${accountId}/content?tab=projects`}>
                  <Button className="cta-button" type="secondary">
                    Go to DataWars projects
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}

export default ProjectsAnalyticsTable
