import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google'
import App from '@/App'
import store from '@/store'
import * as serviceWorker from '@/serviceWorker'
import reportWebVitals from '@/reportWebVitals'
import { Hotjar } from '@/utils/hotjar'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ReduxProvider store={store}>
    <GoogleOAuthProvider clientId={import.meta.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <Hotjar />

      <App />
    </GoogleOAuthProvider>
  </ReduxProvider>,
)

serviceWorker.unregister()

reportWebVitals()
