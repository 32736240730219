import { Outlet } from 'react-router-dom'
import usePageTracking from '@/hooks/usePageTracking'

const RouteTracking = ({ children, ...props }) => {
  usePageTracking()

  return children || <Outlet />
}

export default RouteTracking
