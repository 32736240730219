import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  > .header-container {
    min-height: 72px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;

    .info {
      display: flex;
      align-items: center;
      gap: 16px;

      .title-container {
        display: flex;
        display: flex;
        align-items: center;
        gap: 4px;

        .title {
          white-space: nowrap;
          margin: 0px;
        }
      }
    }
  }

  > .content {
    height: 100%;
    width: 100%;
    overflow: auto;

    .ant-tabs {
      height: 100%;

      .ant-tabs-nav {
        background-color: ${(props) => props.theme.colors.gray1};
        padding: 0px 24px;
        margin: 0px;

        .ant-tabs-tab {
          padding-top: 0px;
        }
      }

      .ant-tabs-content-holder {
        height: 100%;

        .ant-tabs-content {
          height: 100%;

          .ant-tabs-tabpane {
            height: 100%;
          }
        }
      }
    }

    .tab-content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 16px 24px;
    }
  }

  @media ${device.mdDown} {
    > .header-container {
      padding: 0px 12px;

      .info {
        .title-container {
          .title {
            font-size: 16px;
            display: none;

            .divider {
              display: none;
            }
          }
        }
      }
    }

    > .content {
      .ant-tabs {
        .ant-tabs-nav {
          .ant-tabs-tab {
            font-size: 13px;
          }
        }
      }

      .tab-content {
        padding: 0px;
      }
    }
  }
`
