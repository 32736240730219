import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Table, Modal, Empty, Spin, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  MailRegular,
  Star24Regular,
  Star24Filled,
  Archive24Regular,
  ArchiveArrowBackRegular,
} from '@fluentui/react-icons'
import { djangoAdminURL } from '@/helpers/env'
import { showToast } from '@/utils/toast'
import { capitalize } from '@/utils/functions'
import Button from '@/components/Button'
import { updateAccount, resetAccountsState } from '@/store/accounts/actions'
import DjangoIcon from '@/assets/images/icons/django.svg?react'
import { Container } from './styles'

const Results = ({ results, status, page, setPage, pageSize, setPageSize }) => {
  const dispatch = useDispatch()

  const { isAccountLoading } = useSelector((state) => state.accounts)

  const isLoading = !results?.params || status === 'loading' || status === 'stalled'

  const [items, setItems] = useState()

  const handleTableChange = (pagination) => {
    const newPage = pagination?.current
    const newPageSize = pagination?.pageSize

    setPage(newPage)
    setPageSize(newPageSize)
  }

  const toggleStarAccount = (evt, account) => {
    evt.stopPropagation()

    const auxData = {
      is_favorite: !account.is_favorite,
    }

    account.is_favorite = !account.is_favorite

    dispatch(
      updateAccount(account.id, auxData, () => {
        showToast(`Account ${account.is_favorite ? 'added to' : 'removed from'} favorites`)
      }),
    )
  }

  const toggleArchiveAccount = (account) => {
    const isActive = account?.status === 'active'

    Modal.confirm({
      title: isActive ? 'Archive account' : 'Restore account',
      content: `Are you sure you want to ${isActive ? 'archive' : 'restore'} "${account?.name}" account?`,
      icon: <ExclamationCircleOutlined />,
      okText: `Yes, ${isActive ? 'archive' : 'restore'}`,
      cancelText: 'Cancel',
      onOk: () => {
        const newStatus = isActive ? 'archived' : 'active'

        const auxData = {
          status: newStatus,
        }

        account.status = newStatus

        dispatch(
          updateAccount(account.id, auxData, () => {
            const auxAccounts = items?.filter((a) => a.status !== newStatus)
            setItems(auxAccounts)
            showToast(`Account ${newStatus === 'active' ? 'restored' : 'archived'} successfully`)
          }),
        )
      },
      okButtonProps: {
        danger: isActive,
        type: 'primary',
      },
    })
  }

  const copyEmailToClipboard = (email) => {
    navigator.clipboard.writeText(email)
    showToast('Email copied to clipboard')
  }

  const goToDjangoAdminAccount = (account) => {
    const url = `${djangoAdminURL}teams/account/${account.id}`
    window.open(url, '_blank')
  }

  const columns = [
    {
      title: 'Account',
      dataIndex: 'account',
      fixed: 'left',
    },
    {
      title: 'Members',
      dataIndex: 'members',
    },
    {
      title: 'Total licenses',
      dataIndex: 'totalLicenses',
    },
    {
      title: 'Used licenses',
      dataIndex: 'usedLicenses',
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
    },
    {
      title: 'Last access',
      dataIndex: 'ownerLastAccess',
    },
    {
      title: 'Last activity',
      dataIndex: 'lastActivity',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
    },
    {
      title: 'Expires at',
      dataIndex: 'expiresAt',
    },
    {
      title: '',
      dataIndex: 'actions',
    },
  ]

  const data = items?.map((a) => ({
    key: a.id,
    account: (
      <div className="account-item" onClick={() => dispatch(resetAccountsState())}>
        <Link className="link" to={`/accounts/${a?.id}`}>
          <div className="account-image-container">
            {a?.image_url && <img className="account-image" src={a?.image_url} alt={a?.name} />}
          </div>

          <p className="title">{a?.name}</p>
        </Link>

        <Tooltip title={a?.is_favorite ? 'Remove favorite' : 'Set favorite'}>
          <Button
            className={`star-btn ${a?.is_favorite ? 'is-favorite' : ''}`}
            type="circle"
            icon={isAccountLoading ? <Spin size="small" /> : a?.is_favorite ? <Star24Filled /> : <Star24Regular />}
            onClick={(evt) => toggleStarAccount(evt, a)}
            disabled={isAccountLoading}
          />
        </Tooltip>
      </div>
    ),
    members: a?.users_count,
    totalLicenses: a?.licenses_summary?.total,
    usedLicenses: a?.licenses_summary?.assigned,
    owner: (
      <div className="account-owners">
        {a?.owners?.map((owner) => (
          <p key={owner.username} className="account-owner">
            <span>
              {capitalize(owner.first_name)} {capitalize(owner.last_name)}
            </span>

            <Tooltip title={'Copy email to clipboard'}>
              <Button
                className="mail-btn"
                type="circle"
                icon={<MailRegular />}
                onClick={() => copyEmailToClipboard(owner.email)}
              />
            </Tooltip>
          </p>
        ))}
      </div>
    ),
    ownerLastAccess: a?.owners_last_access ? dayjs.unix(a?.owners_last_access).from() : '—',
    lastActivity: a?.users_last_activity ? dayjs.unix(a?.users_last_activity).from() : '—',
    createdAt: dayjs.unix(a?.created).format('DD/MM/YYYY'),
    expiresAt: a?.license_expires_at ? dayjs.unix(a?.license_expires_at).format('DD/MM/YYYY') : '—',
    actions: (
      <div className="account-actions">
        <Tooltip title={a?.status === 'active' ? 'Archive' : 'Restore'}>
          <Button
            className={a?.status === 'active' ? 'archive-btn' : 'restore-btn'}
            type="circle"
            icon={
              isAccountLoading ? (
                <Spin size="small" />
              ) : a?.status === 'active' ? (
                <Archive24Regular />
              ) : (
                <ArchiveArrowBackRegular />
              )
            }
            onClick={() => toggleArchiveAccount(a)}
            disabled={isAccountLoading}
          />
        </Tooltip>

        <Tooltip title="View in Django admin">
          <Button
            className="django-btn"
            type="circle"
            icon={<DjangoIcon />}
            onClick={() => goToDjangoAdminAccount(a)}
          />
        </Tooltip>
      </div>
    ),
  }))

  useEffect(() => {
    if (!results?.hits) return

    const auxItems = results?.hits.map((hit) => {
      const auxHit = {
        ...hit,
        id: hit?.objectID,
      }
      return auxHit
    })

    setItems(auxItems)
  }, [results?.hits])

  return (
    <Container className="results-container">
      <Table
        className="results-table"
        columns={columns}
        dataSource={data}
        loading={isLoading}
        onChange={handleTableChange}
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No accounts found" /> }}
        pagination={{
          current: page,
          pageSize,
          total: results?.nbHits,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} accounts`,
        }}
        scroll={{
          y: true,
          x: true,
        }}
      />
    </Container>
  )
}

export default Results
