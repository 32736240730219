import { useDispatch } from 'react-redux'
import Button from '@/components/Button'
import { PlayCircleFilled } from '@fluentui/react-icons'
import WelcomeTeams from '@/assets/images/welcome-teams.png'
import { setWelcomeModalOpen } from '@/store/accounts/actions'
import { Container } from './styles'

const WelcomeCard = ({ className }) => {
  const dispatch = useDispatch()

  return (
    <Container className={`card welcome-card ${className}`}>
      <div className="content">
        <h2 className="title">Welcome</h2>
        <p className="text">Empower your team: track progress, drive growth.</p>

        <div className="actions">
          <Button
            className="welcome-button"
            icon={<PlayCircleFilled />}
            onClick={() => dispatch(setWelcomeModalOpen(true))}
          >
            Watch quickstart
          </Button>
        </div>
      </div>

      <div className="image-container">
        <img className="bg-image pattern" src={WelcomeTeams} alt="DataWars Teams" />
        <img className="bg-image" src={WelcomeTeams} alt="DataWars Teams" />
      </div>
    </Container>
  )
}

export default WelcomeCard
