import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Menu, Tooltip } from 'antd'
import { HomeOutlined, UserOutlined, UsergroupAddOutlined, ProductOutlined, LineChartOutlined } from '@ant-design/icons'
import { ChannelArrowLeft24Regular } from '@fluentui/react-icons'
import { isDown } from '@/themes/breakpoints'
import Layout1 from '@/layouts/Layout1'
import GlobalLoader from '@/components/GlobalLoader'
import AssignModal from '@/components/AssignModal'
import DashboardTab from './components/DashboardTab'
import MembersTab from './components/MembersTab'
import TeamsTab from './components/TeamsTab'
import ContentTab from './components/ContentTab'
import AnalyticsTab from './components/AnalyticsTab'
import WelcomeModal from './components/WelcomeModal'
import TeamModal from './components/TeamModal'
import AddToTeamModal from './components/AddToTeamModal'
import InviteModal from './components/InviteModal'
import ModuleDetailModal from './components/ModuleDetailModal'
import PlaygroundDetailModal from './components/PlaygroundDetailModal'
import { fetchAccount, fetchAccountUsers, setWelcomeModalOpen } from '@/store/accounts/actions'
import { Container, MenuContainer } from './styles'

const DashboardPage = () => {
  const dispatch = useDispatch()
  const { accountId, section } = useParams()
  const navigate = useNavigate()

  const { theme } = useSelector((state) => state.app)
  const { userProfile } = useSelector((state) => state.users)
  const { currentAccount: account, accountError } = useSelector((state) => state.accounts)

  const isSuperAccountOwner = userProfile?.permissions?.includes('teams.super_account_owner')
  const isAccountOwner = userProfile?.accounts?.some((a) => a?.role === 'owner')

  const items = [
    {
      label: 'Dashboard',
      key: 'dashboard',
      icon: <HomeOutlined />,
      title: '',
    },
    {
      label: 'Members',
      key: 'members',
      icon: <UserOutlined />,
      title: '',
    },
    {
      label: 'Teams',
      key: 'teams',
      icon: <UsergroupAddOutlined />,
      title: '',
    },
    { label: 'Content', key: 'content', icon: <ProductOutlined />, title: '' },
    { label: 'Analytics', key: 'analytics', icon: <LineChartOutlined />, title: '' },
  ]

  const renderContent = (section) => {
    if (section === 'dashboard') return <DashboardTab />
    if (section === 'members') return <MembersTab />
    if (section === 'teams') return <TeamsTab />
    if (section === 'content') return <ContentTab />
    if (section === 'analytics') return <AnalyticsTab />

    return <DashboardTab />
  }

  useEffect(() => {
    if (!account) return

    const isWelcomeModalClosed = localStorage.getItem('dw-teams-welcome-modal-closed')
    if (!isWelcomeModalClosed) {
      dispatch(setWelcomeModalOpen(true))
      localStorage.setItem('dw-teams-welcome-modal-closed', true)
    }

    dispatch(fetchAccountUsers(account?.id, { role: 'member', page_size: 'None' }))
  }, [account])

  useEffect(() => {
    let validSections = ['dashboard', 'members', 'teams', 'content', 'analytics']

    const isValidSection = validSections.includes(section)

    if (!section || !isValidSection) {
      navigate(`/accounts/${accountId}/dashboard`)
    }

    if (!account || section === 'dashboard') {
      dispatch(fetchAccount(accountId))
    }
  }, [section])

  useEffect(() => {
    if (!accountId || accountError) {
      navigate('/not-found')
    }
  }, [accountId, accountError])

  if (!account) return <GlobalLoader />

  return (
    <Layout1 navbar>
      <Container className="dashboard-page-container">
        <MenuContainer className="menu-container">
          <div className="top-actions">
            <div className="account-selector">
              <Tooltip title={account?.name}>
                <div className="account-image-container">
                  {account?.image_url && <img className="account-image" src={account?.image_url} alt={account?.name} />}
                </div>
              </Tooltip>
            </div>

            <Menu
              className="account-menu"
              inlineCollapsed={isDown('lg')}
              selectedKeys={[section]}
              defaultOpenKeys={['sub1']}
              mode="inline"
              theme={theme === 'dark' ? 'dark' : 'light'}
              items={items}
              onClick={({ key }) => navigate(`/accounts/${accountId}/${key}`)}
            />
          </div>

          {(isSuperAccountOwner || isAccountOwner) && (
            <div className="bottom-actions">
              <div className="menu-item" onClick={() => navigate('/accounts')}>
                <ChannelArrowLeft24Regular className="icon" />
                <p className="text">Accounts</p>
              </div>
            </div>
          )}
        </MenuContainer>

        {renderContent(section)}

        <WelcomeModal />
        <AssignModal />
        <TeamModal />
        <AddToTeamModal />
        <InviteModal />
        <ModuleDetailModal />
        <PlaygroundDetailModal />
      </Container>
    </Layout1>
  )
}

export default DashboardPage
