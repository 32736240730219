import { useEffect } from 'react'
import prism from '@/utils/prism'

const CodeBlock = ({ language, children }) => {
  useEffect(() => {
    // execute when DOM is ready
    setTimeout(() => prism.highlightAll(), 0)
  }, [])

  return (
    <pre className={`language-${language}`}>
      <code className={`language-${language}`}>{children}</code>
    </pre>
  )
}

export default CodeBlock
