import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: null,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    SET_SESSIONS_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    SESSIONS_UPDATED: (state, { payload }) => {
      state.items = payload
      state.error = null
    },
    SET_SESSIONS_ERROR: (state, { payload }) => {
      state.error = payload
    },
    RESET_SESSIONS_STATE: () => initialState,
  },
})

export const { SET_SESSIONS_LOADING, SESSIONS_UPDATED, SET_SESSIONS_ERROR, RESET_SESSIONS_STATE } = slice.actions
export default slice.reducer
