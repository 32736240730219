import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  playgroundsData: null,
  isPlaygroundPreviewLoading: null,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'playgrounds',
  initialState,
  reducers: {
    SET_PLAYGROUNDS_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    PLAYGROUNDS_DATA_UPDATED: (state, { payload }) => {
      state.playgroundsData = payload
      state.error = null
    },
    SET_PLAYGROUND_PREVIEW_LOADING: (state, { payload }) => {
      state.isPlaygroundPreviewLoading = payload
    },
    RESET_PLAYGROUNDS_STATE: () => initialState,
    SET_PLAYGROUNDS_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
  },
})

export const {
  SET_PLAYGROUNDS_LOADING,
  PLAYGROUNDS_DATA_UPDATED,
  SET_PLAYGROUND_PREVIEW_LOADING,
  RESET_PLAYGROUNDS_STATE,
  SET_PLAYGROUNDS_ERROR,
} = slice.actions
export default slice.reducer
