import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  .table-header {
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto hidden;
    padding: 14px 24px;

    .filters {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      flex: 1 1 100%;

      .user-search {
        min-width: 200px;
        max-width: 300px;
        width: 100%;

        .ant-input-search-button {
          cursor: default;

          &:hover {
            border-color: ${(props) => props.theme.colors.gray5};
          }

          > div {
            display: none;
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
  }

  .members-table {
    height: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    overflow: auto;

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;

        .ant-table {
          height: calc(100% - 60px);
        }

        .ant-pagination {
          justify-content: flex-start;
        }
      }
    }

    .ant-table-body {
      max-height: calc(100vh - 388px) !important;
      overflow: auto !important;
    }

    .team-tag {
      text-transform: capitalize;
      margin-right: 8px;

      &:last-of-type {
        margin: 0px;
      }
    }
  }

  @media ${device.lgDown} {
    .members-table {
      .ant-table-body {
        max-height: calc(100vh - 446px) !important;
      }
    }
  }

  @media ${device.mdDown} {
    gap: 12px;

    .table-header {
      max-height: 90px;
      height: 100%;
      border-top: 1px solid ${(props) => props.theme.colors.gray4};
      padding: 0px 14px;

      .filters {
        flex-direction: column;

        .user-search {
          max-width: initial;
        }

        .actions {
          justify-content: flex-start;
        }
      }
    }

    .members-table {
      .ant-table-body {
        max-height: calc(100vh - 436px) !important;

        .ant-table-cell {
          font-size: 12px;
          padding: 10px;
        }
      }
    }
  }
`
