import { useNavigate } from 'react-router-dom'
import { useRouteError } from 'react-router-dom'
import { platformURL } from '@/helpers/env'
import Layout1 from '@/layouts/Layout1'
import ErrorCard from './components/ErrorCard'
import { Container } from './styles'

const ErrorPage = () => {
  const navigate = useNavigate()
  const error = useRouteError()

  const refreshPage = () => {
    window.location.reload()
  }

  const goToLearnPlatform = () => {
    navigate(platformURL, { replace: true })
  }

  return (
    <Layout1 navbar>
      <Container>
        <ErrorCard
          title={'An unexpected error has occurred.'}
          text={error.statusText || error.message}
          buttonText={'Refresh this page'}
          buttonOnClick={refreshPage}
          bottomText={'or go to Learn platform'}
          bottomOnClick={goToLearnPlatform}
        />
      </Container>
    </Layout1>
  )
}

export default ErrorPage
