import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .catalog-header {
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto hidden;
    padding: 12px 24px;

    .search-box {
      min-width: 200px;
      max-width: 300px;
      width: 100%;
      background-color: ${(props) => props.theme.colors.gray1};
      border: 1px solid ${(props) => props.theme.colors.base.gray5};
      border-radius: 2px;
      position: relative;
      display: flex;
      align-items: center;

      .icon {
        height: 100%;
        width: 31px;
        background-color: ${(props) => props.theme.colors.gray2};
        border-left: 1px solid ${(props) => props.theme.colors.gray5};
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        right: 0px;

        svg {
          color: ${(props) => props.theme.colors.gray13}40;
          font-size: 16px;
        }
      }

      .ais-SearchBox {
        width: 100%;

        input {
          width: 100%;
          font-size: 14px;
          border: 0px;
          outline: 0px;
          padding: 7px 38px 7px 10px;
        }

        .ais-SearchBox-loadingIndicator,
        .ais-SearchBox-submit,
        .ais-SearchBox-reset {
          display: none;
        }
      }
    }
  }

  .catalog-body {
    max-height: calc(100% - 72px);
    width: calc(100% + 20px);
    display: flex;
    justify-content: center;
    overflow: auto;
    margin: -10px -10px 0px;

    > .content {
      max-width: 1440px;
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
    }

    .ant-drawer {
      .ant-drawer-header-title {
        flex-direction: row-reverse;
      }
    }
  }

  @media ${device.xlDown} {
    .catalog-body {
      width: 100%;
      margin: 0px;

      > .content {
        flex-direction: column;
      }
    }
  }
`
