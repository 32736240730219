import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'antd'
import { UsergroupAddOutlined } from '@ant-design/icons'
import Button from '@/components/Button'
import TeamsTable from '@/pages/MainPage/components/TeamsTable'
import { setTeamModalOpen } from '@/store/accounts/actions'
import { Container } from './styles'

const TeamsCard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { currentAccount: account, teamsData, isLoading: isAccountsLoading } = useSelector((state) => state.accounts)

  const renderEmptyState = () => {
    return (
      <div className="content empty-state">
        <div className="header">
          <div className="container">
            <div className="title-container">
              <UsergroupAddOutlined className="icon" />
              <h4 className="title">Organize your users in teams</h4>
            </div>

            <p className="text">Assign content and track analytics for your teams</p>

            <Button type="secondary" onClick={() => dispatch(setTeamModalOpen('create'))}>
              Create team
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Container className="card teams-card">
      {isAccountsLoading ? (
        <div className="loading-content">
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton className="teams-table" active title={false} paragraph={{ rows: 1 }} />
        </div>
      ) : teamsData?.results?.length ? (
        <div className="content">
          <div className="header">
            <div className="title-container">
              <UsergroupAddOutlined className="icon" />
              <p className="title">Total Teams ({teamsData?.count || 0})</p>
            </div>

            <Button type="secondary" onClick={() => dispatch(setTeamModalOpen('create'))}>
              Create team
            </Button>
          </div>

          <div className="table-container">
            <TeamsTable size={'small'} hideActions isPreview />

            <div className="table-overlay">
              <Button className="cta-button" type="text" onClick={() => navigate(`/accounts/${account?.id}/teams`)}>
                View all teams
              </Button>
            </div>
          </div>
        </div>
      ) : (
        renderEmptyState()
      )}
    </Container>
  )
}

export default TeamsCard
