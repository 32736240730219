import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  max-width: 560px;
  width: 100% !important;

  .ant-modal-content {
    padding: 0px;
  }

  .ant-modal-body {
    .container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 20px;
      padding: 40px 50px 26px;

      > .header {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .title {
          margin: 0px;
        }
      }

      .account-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 14px;

        .account-image-container {
          max-width: 82px;
          min-width: 82px;
          max-height: 82px;
          min-height: 82px;
          background: linear-gradient(86deg, #39476d 8.49%, #4150d0 67.75%, rgba(179, 114, 244, 0.62) 97.13%);
          border-radius: 7px;
          position: relative;
          overflow: hidden;

          .account-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
          }

          .upload-image-button-container {
            height: 100%;
            width: 100%;
            background-color: ${(props) => props.theme.colors.gray10}aa;
            position: absolute;
            top: 0px;
            left: 0px;
            display: none;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .ant-upload-wrapper {
              width: 100%;
              height: 100%;

              .ant-upload {
                width: 100%;
                height: 100%;

                .icon-button {
                  width: 100%;
                  height: 100%;

                  .ant-btn-icon span {
                    color: ${(props) => props.theme.colors.gray1};
                    font-size: 24px;
                  }
                }
              }

              .ant-upload-list {
                .ant-upload-list-item-container {
                  display: none !important;
                }

                .ant-upload {
                  width: fit-content !important;
                  height: fit-content !important;
                  margin: 0px !important;

                  &.ant-upload-select {
                    background-color: transparent !important;
                    border: 0px !important;
                  }
                }
              }
            }
          }

          &:hover {
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);

            .upload-image-button-container {
              display: flex;
            }
          }
        }

        .info {
          width: 100%;
          display: flex;
          flex-direction: column;

          .title {
            font-weight: 500;
            margin: 0px 0px 10px;
          }
        }
      }

      .actions {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`
