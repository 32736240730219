import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { debounce } from 'lodash'
import { Input, Pagination, Segmented } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { CollectionsAdd24Regular } from '@fluentui/react-icons'
import { platformURL } from '@/helpers/env'
import Button from '@/components/Button'
import PlaygroundBox from '@/components/PlaygroundBox'
import { setUserSearchText } from '@/store/accounts/actions'
import { getPlaygrounds } from '@/store/playgrounds/actions'
import NoResults from '@/assets/images/no-results.svg'
import { Container } from './styles'

const CustomProjectsTab = () => {
  const dispatch = useDispatch()
  const { accountId } = useParams()

  const { pageSize: defaultPageSize } = useSelector((state) => state.app)
  const { userSearchText } = useSelector((state) => state.accounts)
  const { playgroundsData, isLoading } = useSelector((state) => state.playgrounds)

  const hasResults = playgroundsData?.results?.length > 0

  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [currentPage, setCurrentPage] = useState(1)
  const [statusFilter, setStatusFilter] = useState()

  const handleLoad = async () => {
    dispatch(getPlaygrounds({ account_id: accountId, is_student_copy: false }))
  }

  const handleSearchProjects = (newPage, newPageSize, search = userSearchText) => {
    setCurrentPage(newPage || 1)

    if (newPageSize) {
      setPageSize(newPageSize)
    }

    dispatch(
      getPlaygrounds({
        account_id: accountId,
        is_student_copy: false,
        status: statusFilter === 'all' ? null : statusFilter,
        search,
        page_size: newPageSize || pageSize,
        page: newPage || 1,
      }),
    )
  }

  const handleUserSearch = useMemo(
    () =>
      debounce((evt) => {
        const search = evt?.target?.value?.toLowerCase()

        handleSearchProjects(null, null, search)
      }, 300),
    [statusFilter],
  )

  const handleTableChange = (page, pageSize) => {
    handleSearchProjects(page, pageSize)
  }

  useEffect(() => {
    if (!statusFilter) return

    handleSearchProjects(currentPage, pageSize)
  }, [statusFilter])

  useEffect(() => {
    handleLoad()

    return () => setStatusFilter('all')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="custom-projects-tab">
      {(hasResults || (!hasResults && userSearchText) || (statusFilter && statusFilter !== 'all')) && (
        <div className="header">
          <div className="search-container">
            <Input.Search
              className="user-search"
              value={userSearchText}
              onChange={(evt) => {
                dispatch(setUserSearchText(evt?.target?.value?.toLowerCase()))
                handleUserSearch(evt)
              }}
              allowClear
              placeholder="Search projects here..."
              loading={isLoading}
              disabled={isLoading}
            />
          </div>

          <Segmented
            className="status-filter"
            options={[
              { value: 'all', label: 'All' },
              { value: 'published', label: 'Published' },
              { value: 'archived', label: 'Archived' },
            ]}
            defaultValue={'all'}
            value={statusFilter}
            onChange={setStatusFilter}
          />
        </div>
      )}

      <div className="body">
        {isLoading ? (
          <div className="content">
            <div className="playground-box-container">
              <PlaygroundBox isBoxLoading />
            </div>
            <div className="playground-box-container">
              <PlaygroundBox isBoxLoading />
            </div>
            <div className="playground-box-container">
              <PlaygroundBox isBoxLoading />
            </div>
            <div className="playground-box-container">
              <PlaygroundBox isBoxLoading />
            </div>
            <div className="playground-box-container">
              <PlaygroundBox isBoxLoading />
            </div>
            <div className="playground-box-container">
              <PlaygroundBox isBoxLoading />
            </div>
            <div className="playground-box-container">
              <PlaygroundBox isBoxLoading />
            </div>
          </div>
        ) : hasResults || (!hasResults && userSearchText) || (statusFilter && statusFilter !== 'all') ? (
          <div className="content">
            {playgroundsData?.results?.map((p) => (
              <div className="playground-box-container">
                <PlaygroundBox playground={p} key={p.id} />
              </div>
            ))}

            {hasResults ? (
              <div className="pagination-container">
                <Pagination
                  current={currentPage}
                  onChange={handleTableChange}
                  total={playgroundsData?.count}
                  pageSize={pageSize}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} projects`}
                />
              </div>
            ) : (
              <div className="no-results-box">
                <img src={NoResults} alt="no-results" />
                <h4 className="title">No projects found</h4>
                <h5 className="text">
                  {userSearchText
                    ? `“${userSearchText}” did not match any project.`
                    : `You don't have ${statusFilter} projects.`}
                  <br />

                  {userSearchText && 'Please try again.'}
                </h5>
              </div>
            )}
          </div>
        ) : (
          <div className="empty-state-container">
            <div className="empty-state-content">
              <div className="header">
                <div className="container">
                  <div className="title-container">
                    <CollectionsAdd24Regular className="icon" />

                    <h4 className="title">Custom projects</h4>
                  </div>

                  <p className="text">Create custom projects and track your users' progress.</p>

                  <Link className="link" to={`${platformURL}custom-projects/${accountId}/create`} target="_blank">
                    <Button type="secondary" icon={<PlusOutlined />} onClick={() => {}}>
                      Create project
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export default CustomProjectsTab
