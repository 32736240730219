const educationLevels = [
  { value: 'preschool or nursery school', label: 'Preschool or Nursery School' },
  { value: 'primary school', label: 'Primary School (Elementary School in some regions)' },
  { value: 'secondary school', label: 'Secondary School (High School in some regions)' },
  { value: 'vocational training or apprenticeship', label: 'Vocational Training or Apprenticeship' },
  { value: "associate's degree or diploma", label: "Associate's Degree or Diploma" },
  { value: "bachelor's degree", label: "Bachelor's Degree" },
  { value: "master's degree", label: "Master's Degree" },
  { value: 'doctoral degree', label: 'Doctoral Degree (Ph.D., Ed.D., etc.)' },
  { value: 'professional degrees', label: 'Professional Degrees (MD, JD, DDS, etc.)' },
]

export default educationLevels
