import { SET_USERS_LOADING, USER_AUTHENTICATION_UPDATED, USER_PROFILE_UPDATED, SET_USERS_ERROR } from '@/store/users'
import {
  postRegisterUser,
  postAuthUserWithGoogle,
  postAuthenticateUser,
  postLogoutUser,
  fetchUserMe,
  postForgotPassword,
  postResetPassword,
} from '@/services/users'
import { showToast } from '@/utils/toast/index'

const signup = (userData, callback) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    const user = await postRegisterUser(userData)

    if (user) {
      dispatch(USER_PROFILE_UPDATED(user))
    }

    if (callback) {
      // to avoid redirect because of authenticated user
      setTimeout(() => callback())
    }
  } catch (error) {
    dispatch(USER_AUTHENTICATION_UPDATED(false))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const authWithGoogle = (data, callback) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    // const user = await postAuthUserWithGoogle(data)
    await postAuthUserWithGoogle(data)

    await dispatch(
      tryAutoLogin(
        () => showToast('Welcome!'),
        () => showToast('User could not be verified', 'error'),
      ),
    )

    if (callback) {
      callback()
    }
  } catch (error) {
    dispatch(USER_AUTHENTICATION_UPDATED(false))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const login = (userCredentials, callback) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    await postAuthenticateUser(userCredentials)
    await dispatch(
      tryAutoLogin(
        () => showToast('Welcome!'),
        () => showToast('User could not be verified', 'error'),
      ),
    )

    if (callback) {
      callback()
    }
  } catch (error) {
    dispatch(USER_AUTHENTICATION_UPDATED(false))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const tryAutoLogin = (onSuccess, onError) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    const user = await fetchUserMe()

    if (user) {
      dispatch(USER_PROFILE_UPDATED(user))
    } else {
      dispatch(USER_AUTHENTICATION_UPDATED(false))
    }

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    if (onError) {
      onError()
    }
    dispatch(USER_AUTHENTICATION_UPDATED(false))
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const logout = () => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))
    await postLogoutUser()

    dispatch(USER_AUTHENTICATION_UPDATED(false))
    dispatch(USER_PROFILE_UPDATED(null))

    showToast('Come back soon!')
  } catch (error) {
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const forgotPassword = (data) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    await postForgotPassword(data)
    showToast('An email has been sent with instructions on how to reset your password', 'success')
  } catch (error) {
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const resetPassword = (data) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    const res = await postResetPassword(data)
    showToast('New password set successfully', 'success')
    return res
  } catch (error) {
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

export { signup, authWithGoogle, login, tryAutoLogin, logout, forgotPassword, resetPassword }
