import { platformURL } from '@/helpers/env'
import Layout1 from '@/layouts/Layout1'
import Button from '@/components/Button'
import ErrorImage from '@/assets/images/error.gif'
import { Container } from './styles'

const NotFoundPage = () => {
  const goToLearnPlatform = () => {
    window.location.replace(platformURL)
  }

  return (
    <Layout1 navbar>
      <Container>
        <div className="not-found-card-container">
          <img src={ErrorImage} alt={'Not found'} />

          <h3 className="title">Oops! Page not found</h3>
          <p className="text-body">
            The page you are looking for can't be found. <br />
            Check the web address and try again.
          </p>

          <div className="actions">
            <Button className="button" type="primary" onClick={goToLearnPlatform}>
              Go to Learn platform
            </Button>
          </div>
        </div>
      </Container>
    </Layout1>
  )
}

export default NotFoundPage
