import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.gray1};
  border-radius: 10px;
  overflow: hidden;

  > .loading-content {
    padding: 20px 30px 10px;

    .chart-loading {
      .ant-skeleton-paragraph li {
        width: 100% !important;
        height: 148px;
      }
    }
  }

  > .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    gap: 10px;
    padding: 28px 28px 12px;

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-container {
        display: flex;
        align-items: center;
        gap: 8px;

        .icon svg {
          height: 20px;
          width: 20px;
        }

        .title {
          font-size: 16px;
          margin: 0px;
        }
      }
    }

    .analytics-container {
      max-height: 100%;
      display: block;
      position: relative;

      canvas {
        max-width: 100%;
      }

      .no-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        .info {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          flex: 1;

          .title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            margin: 0px;

            .icon {
              font-size: 24px;
              color: ${(props) => props.theme.colors.geekblue5};
            }
          }

          .text {
            color: ${(props) => props.theme.colors.gray7};
            font-weight: 400;
            margin: 6px 0px 0px;
          }
        }

        .image {
          height: fit-content;
          max-width: 260px;
          width: 100%;
        }

        .actions {
          display: flex;
          gap: 8px;
          margin-top: 16px;
        }
      }
    }
  }
`
