import { SET_SKILL_TRACKS_LOADING, SKILL_TRACKS_UPDATED, SET_SKILL_TRACK_ERROR } from '@/store/skillTracks'
import { getAlgoliaSkillTracks } from '@/services/skillTracks'

const fetchSkillTracks = (params) => async (dispatch) => {
  try {
    dispatch(SET_SKILL_TRACKS_LOADING(true))

    const skillTracks = await getAlgoliaSkillTracks(params)
    dispatch(SKILL_TRACKS_UPDATED(skillTracks))
  } catch (error) {
    const { message } = error
    dispatch(SET_SKILL_TRACK_ERROR(message))
  } finally {
    dispatch(SET_SKILL_TRACKS_LOADING(false))
  }
}

export { fetchSkillTracks }
