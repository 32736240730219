import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  &#account-type-selector {
    width: fit-content;
  }

  &#create-account-form {
    min-height: 450px;
  }

  .steps-container {
    max-width: 220px;
    width: 100%;
    padding: 14px 0px;
  }

  > .header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      margin-bottom: 0px;
    }

    .subtitle {
      color: ${(props) => props.theme.colors.gray7};
      margin-bottom: 0px;
    }
  }

  .form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .form-body {
      .step {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &.hidden {
          display: none;
        }
      }
    }
  }

  .org-type-container {
    display: flex;

    .org-type {
      height: 200px;
      width: 220px;
      background-color: ${(props) => props.theme.colors.gray1};
      border: 1px solid ${(props) => props.theme.colors.gray5};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 14px;
      transition: background-color 0.3s;
      cursor: pointer;

      > img {
        user-select: none;
      }

      .type-name {
        font-size: 13px;
        margin: 0px;
      }

      .text {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        opacity: 0.8;
        margin: 0px;
      }

      &:first-of-type {
        border-right: 1px solid transparent;
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.geekblue1};
        opacity: 1;
      }

      &.is-selected {
        border: 2px solid ${(props) => props.theme.colors.geekblue5};
      }
    }
  }

  .error-container {
    max-width: 360px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: -16px auto;

    > p {
      text-align: center;
    }

    .error-text {
      color: ${(props) => props.theme.colors.red5};
      font-size: 13px;
      margin: 0px;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  @media ${device.mdDown} {
    .steps-container {
      display: none;
    }
  }
`
