import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    SET_ACTIVITIES_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    SET_ACTIVITIES_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
    RESET_ACTIVITIES_STATE: () => initialState,
  },
})

export const { SET_ACTIVITIES_LOADING, SET_ACTIVITIES_ERROR, RESET_ACTIVITIES_STATE } = slice.actions
export default slice.reducer
