import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import CustomProjectsEmptyState from '@/assets/images/empty-state-custom-projects.png'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .main-content {
    height: calc(100% - 154px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    > .header {
      width: 100%;
      background-color: ${(props) => props.theme.colors.gray1};
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: auto hidden;
      padding: 12px 24px;

      .search-box {
        min-width: 200px;
        max-width: 300px;
        width: 100%;
        background-color: ${(props) => props.theme.colors.gray1};
        border: 1px solid ${(props) => props.theme.colors.base.gray5};
        border-radius: 2px;
        position: relative;
        display: flex;
        align-items: center;

        .icon {
          height: 100%;
          width: 31px;
          background-color: ${(props) => props.theme.colors.gray2};
          border-left: 1px solid ${(props) => props.theme.colors.gray5};
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0px;
          right: 0px;

          svg {
            color: ${(props) => props.theme.colors.gray13}40;
            font-size: 16px;
          }
        }

        .ais-SearchBox {
          width: 100%;

          input {
            width: 100%;
            font-size: 14px;
            border: 0px;
            outline: 0px;
            padding: 7px 38px 7px 10px;
          }

          .ais-SearchBox-loadingIndicator,
          .ais-SearchBox-submit,
          .ais-SearchBox-reset {
            display: none;
          }
        }
      }
    }

    > .body {
      max-height: calc(100% - 72px);
      width: calc(100% + 20px);
      display: flex;
      justify-content: center;
      overflow: auto;
      margin: -10px -10px 0px;

      > .content {
        max-width: 1440px;
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
      }

      .ant-drawer {
        .ant-drawer-header-title {
          flex-direction: row-reverse;
        }
      }
    }
  }

  .empty-state {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    animation: 1s ${fadeInAnimation};

    .empty-state-content {
      height: 100%;
      width: 100%;
      background: url(${CustomProjectsEmptyState}) no-repeat center center;
      background-size: cover;

      > .header {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 24px 36px;

        .container {
          max-width: 460px;

          .title-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;

            .icon {
              min-width: 24px;
              font-size: 24px;
              color: ${(props) => props.theme.colors.geekblue5};
            }

            .title {
              margin: 0px 0px 12px;

              .beta {
                color: ${(props) => props.theme.colors.cyan6};
                font-size: 16px;
                letter-spacing: 1.5px;
                text-transform: uppercase;
                padding: 0px 3px;
              }
            }
          }

          .text {
            color: ${(props) => props.theme.colors.gray7};
            margin: 0px 0px 20px;
          }

          .link {
            color: ${(props) => props.theme.colors.gray7};
            text-decoration: underline;
          }

          > button {
            margin-top: 18px;
          }
        }
      }
    }

    &.small {
      height: 154px;

      .empty-state-content {
        > .header {
          padding: 12px 36px;

          .container {
            .title-container {
              flex-direction: row;
              align-items: flex-start;
            }

            .text {
              margin: 0px;
            }
          }
        }
      }
    }
  }

  @media ${device.xlDown} {
    > .main-content {
      > .body {
        width: 100%;
        margin: 0px;

        > .content {
          flex-direction: column;
        }
      }
    }
  }

  @media ${device.mdDown} {
    .empty-state {
      &.small {
        display: none;
      }

      .empty-state-content {
        > .header {
          justify-content: flex-start;
        }
      }
    }
  }
`
