import { SET_SESSIONS_LOADING, SESSIONS_UPDATED, SET_SESSIONS_ERROR, RESET_SESSIONS_STATE } from '@/store/sessions'
import { getSessions } from '@/services/sessions'

const fetchSessions = (params) => async (dispatch) => {
  try {
    dispatch(SET_SESSIONS_LOADING(true))

    const skillTracks = await getSessions(params)
    dispatch(SESSIONS_UPDATED(skillTracks))
  } catch (error) {
    const { message } = error
    dispatch(SET_SESSIONS_ERROR(message))
  } finally {
    dispatch(SET_SESSIONS_LOADING(false))
  }
}

const resetSessionsState = () => (dispatch) => {
  dispatch(RESET_SESSIONS_STATE())
}

export { fetchSessions, resetSessionsState }
