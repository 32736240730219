import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  > .content {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    padding: 4px 6px;
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.colors.gray3};
    }

    .selected-value {
      .team-item-container {
        gap: 8px;

        .name {
          font-size: 20px;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }

    .icon {
      color: ${(props) => props.theme.colors.gray10};
      font-size: 14px;
    }
  }

  @media ${device.mdDown} {
    > .content {
      gap: 4px;
      padding: 0px;

      .selected-value {
        .team-item-container {
          .name {
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
`
