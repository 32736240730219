import { Segmented } from 'antd'
// import { FilterOutlined } from '@ant-design/icons'
// import Button from '@/components/Button'
import { Container } from './styles'

const Filters = ({ statusFilter, setStatusFilter }) => {
  return (
    <Container className="filters-container">
      <Segmented
        className="status-filter"
        options={[
          { value: 'all', label: 'All' },
          { value: 'favorites', label: 'Favorites' },
          { value: 'archived', label: 'Archived' },
        ]}
        value={statusFilter}
        onChange={setStatusFilter}
      />

      {/* <Button type="default" icon={<FilterOutlined />} disabled>
        Filter
      </Button> */}
    </Container>
  )
}

export default Filters
