import { createSlice } from '@reduxjs/toolkit'
import { currentEnv } from '@/helpers/env'

const slice = createSlice({
  name: 'app',
  initialState: {
    environment: currentEnv,
    appError: null,
    appNotification: null,
    theme: localStorage.getItem('dw-theme') || 'light',
    themeConfig: null,
    isReportIssueModalOpen: false,
    isUpgradeToProModalOpen: false,
    pageSize: 20,
  },
  reducers: {
    APP_ERROR_UPDATED: (state, { payload }) => {
      state.appError = payload
    },
    APP_NOTIFICATION_UPDATED: (state, { payload }) => {
      state.appNotification = payload
    },
    APP_THEME_UPDATED: (state, { payload }) => {
      state.theme = payload
    },
    APP_THEME_CONFIG_UPDATED: (state, { payload }) => {
      state.themeConfig = payload
    },
    TOGGLE_REPORT_ISSUE_MODAL: (state, { payload }) => {
      state.isReportIssueModalOpen = payload
    },
    TOGGLE_UPGRADE_TO_PRO_MODAL: (state, { payload }) => {
      state.isUpgradeToProModalOpen = payload
    },
  },
})

export const {
  APP_ERROR_UPDATED,
  APP_NOTIFICATION_UPDATED,
  APP_THEME_UPDATED,
  APP_THEME_CONFIG_UPDATED,
  TOGGLE_REPORT_ISSUE_MODAL,
  TOGGLE_UPGRADE_TO_PRO_MODAL,
} = slice.actions
export default slice.reducer
