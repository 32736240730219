import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PersonClock24Regular } from '@fluentui/react-icons'
import Button from '@/components/Button'
import PendingInvitesTable from '@/pages/MainPage/components/PendingInvitesTable'
import { setInviteModalOpen } from '@/store/accounts/actions'
import { Container } from './styles'

const PendingInvitesCard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { currentAccount: account, allUsers, userInvites } = useSelector((state) => state.accounts)

  if (allUsers && !allUsers?.length) return <></>

  return (
    <Container className="card pending-invites-card">
      <div className="content">
        <div className="info-container">
          <div className="info">
            <div className="value-container">
              <h2 className="value">
                <PersonClock24Regular className="icon" /> {userInvites?.count || 0}
              </h2>
              <p className="text">Pending invite{userInvites?.count === 1 ? '' : 's'}</p>
            </div>

            {!!userInvites?.count && (
              <Button type="default" onClick={() => navigate(`/accounts/${account?.id}/members?tab=pending`)}>
                View all
              </Button>
            )}

            <Button className="cta-button" type="secondary" onClick={() => dispatch(setInviteModalOpen(true))}>
              Create Invite Link
            </Button>
          </div>
        </div>

        <div className="table-container">
          <PendingInvitesTable size={'small'} isPreview />
        </div>
      </div>
    </Container>
  )
}

export default PendingInvitesCard
