import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isAuthenticated: null,
  userProfile: null,
  error: null,
}

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    SET_USERS_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    USER_AUTHENTICATION_UPDATED: (state, { payload }) => {
      state.isAuthenticated = payload
      state.error = null
    },
    USER_PROFILE_UPDATED: (state, { payload }) => {
      const auxPayload = { ...payload }

      state.userProfile = auxPayload
      state.isAuthenticated = !!payload
      state.error = null
    },
    SET_USERS_ERROR: (state, { payload }) => {
      state.error = payload
    },
    RESET_USERS_STATE: () => initialState,
  },
})

export const {
  SET_USERS_LOADING,
  USER_AUTHENTICATION_UPDATED,
  USER_PROFILE_UPDATED,
  SET_USERS_ERROR,
  CLEAR_ERRORS,
  RESET_USERS_STATE,
} = slice.actions
export default slice.reducer
