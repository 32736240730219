import styled, { css } from 'styled-components'
import MembersEmptyState from '@/assets/images/empty-state-members.png'
import MembersEmptyStateDark from '@/assets/images/empty-state-members-dark.png'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.gray1};
  border-radius: 10px;
  overflow: hidden;

  > .loading-content {
    padding: 20px 30px 10px;

    .members-chart {
      .ant-skeleton-paragraph li {
        width: 118px !important;
        height: 118px;
        border-radius: 50%;
        margin: 40px auto 0px;
      }
    }

    .members-table {
      .ant-skeleton-paragraph li {
        width: 100% !important;
        height: 400px;
        margin-top: 40px;
      }
    }
  }

  > .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 28px;

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-container {
        display: flex;
        align-items: center;
        gap: 8px;

        .icon svg {
          height: 20px;
          width: 20px;
        }

        .title {
          font-size: 16px;
          margin: 0px;
        }
      }
    }

    .users-info {
      width: 100%;
      height: 228px;
      display: flex;
      align-items: center;
      justify-content: center;

      .main-circle {
        height: 118px;
        width: 118px;
        background-color: ${(props) => props.theme.colors.gray3};
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;
        padding: 12px;

        .title {
          margin: 0px;
        }

        .text {
          color: ${(props) => props.theme.colors.gray7};
          line-height: 14px;
          margin: 0px;
        }

        .circle {
          border-radius: 50%;
          position: absolute;
          animation: move;
          animation-duration: 1s;

          @keyframes move {
            0% {
              opacity: 0;
              width: 0px;
              height: 0px;
            }
            100% {
              opacity: 1;
            }
          }

          &:nth-child(3) {
            width: 22px;
            height: 22px;
            background-color: ${(props) => props.theme.colors.purple4};
            top: -24px;
            right: initial;
            bottom: initial;
            left: -26px;
          }

          &:nth-child(4) {
            width: 14px;
            height: 14px;
            background-color: ${(props) => props.theme.colors.geekblue3};
            top: -18px;
            right: 10px;
            bottom: initial;
            left: initial;
          }

          &:nth-child(5) {
            width: 14px;
            height: 14px;
            background-color: ${(props) => props.theme.colors.cyan2};
            top: initial;
            right: -47px;
            bottom: 38px;
            left: initial;
          }

          &:nth-child(6) {
            width: 14px;
            height: 14px;
            background-color: ${(props) => props.theme.colors.magenta3};
            top: initial;
            right: 14px;
            bottom: -30px;
            left: initial;
          }

          &:nth-child(7) {
            width: 27px;
            height: 27px;
            background-color: ${(props) => props.theme.colors.cyan2};
            top: initial;
            right: initial;
            bottom: -20px;
            left: -34px;
          }
        }
      }
    }

    .leaderboard-container {
      height: calc(100% - 32px - 228px);
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 24px;
    }

    &.empty-state {
      ${() =>
        localStorage.getItem('dw-theme') === 'dark'
          ? css`
              background: url(${MembersEmptyStateDark}) no-repeat center center;
            `
          : css`
              background: url(${MembersEmptyState}) no-repeat center center;
            `}

      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;

      > .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 0 50%;

        .container {
          max-width: 230px;
          text-align: center;

          .icon {
            font-size: 24px;
            color: ${(props) => props.theme.colors.geekblue5};
            margin-bottom: 10px;
          }

          .title {
            color: ${(props) => props.theme.colors.gray11};
            line-height: 28px;
            margin-bottom: 4px;
          }

          .subtitle {
            color: ${(props) => props.theme.colors.gray7};
            font-size: 16px;
            margin: 0px;
          }

          > button {
            margin-top: 24px;
          }
        }
      }
    }
  }
`
