import { useState } from 'react'
import OverlappedLoader from '@/components/OverlappedLoader'
import ReportIssueModal from '@/components/ReportIssueModal'
import Navbar from './components/Navbar'
import { Layout, Content, ChildrenContent } from './styles'

const Layout1 = ({ navbar, siderContent, disableSiderCollapse, isLoading, isFullScreen, children }) => {
  const [isSiderCollapsed, setSiderCollapsed] = useState(true)

  return (
    <Layout hasSider={false}>
      {navbar && <Navbar isLoading={isLoading} />}

      {isLoading && <OverlappedLoader />}

      <Content fullscreen={isFullScreen?.toString()}>
        {siderContent && (
          <Layout.Sider
            className="sider"
            collapsible={!disableSiderCollapse}
            collapsedWidth={50}
            collapsed={isSiderCollapsed}
            onCollapse={(value) => setSiderCollapsed(value)}
          >
            {siderContent}
          </Layout.Sider>
        )}

        <ChildrenContent className="children-content">{children}</ChildrenContent>
      </Content>

      <ReportIssueModal />
    </Layout>
  )
}

export default Layout1
