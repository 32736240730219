import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  max-width: 520px;
  width: 100% !important;

  .ant-modal-content {
    padding: 32px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;

      .title {
        display: flex;
        align-items: center;
        gap: 6px;
        margin: 0px;

        .icon {
          color: ${(props) => props.theme.colors.geekblue5};
        }
      }

      .text {
        color: ${(props) => props.theme.colors.gray7};
        font-weight: 400;
        margin: 0px;
      }

      .quote {
        background-color: ${(props) => props.theme.colors.geekblue1};
        border-radius: 8px;
        padding: 20px;
      }

      .actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;
        border-top: 1px solid ${(props) => props.theme.colors.gray4};
        padding-top: 20px;

        .share-google-classroom-btn {
          width: 100%;

          > button {
            width: 100%;
          }

          .icon {
            width: 20px;
            display: flex;
          }
        }

        .share-microsoft-teams-btn {
          width: 100%;

          > button {
            width: 100%;
          }

          .icon {
            width: 20px;
            display: flex;
          }
        }

        .copy-link-button {
          width: 100%;

          .icon {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }
`
