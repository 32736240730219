import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Input, Upload, Tooltip } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import Button from '@/components/Button'
import { showToast } from '@/utils/toast/index'
import { updateAccount, updateAccountImage, setEditAccountModalOpen } from '@/store/accounts/actions'
import { Modal } from './styles'

const EditAccountModal = () => {
  const dispatch = useDispatch()

  const { currentAccount, isEditAccountModalOpen, isAccountLoading } = useSelector((state) => state.accounts)

  const [name, setName] = useState()

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      showToast('You can only upload JPG/PNG file!', 'error')
    }

    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      showToast('Image must smaller than 5MB!', 'error')
    }

    return isJpgOrPng && isLt5M
  }

  const handleUpdateAccount = () => {
    dispatch(
      updateAccount(currentAccount?.id, { name }, () => {
        dispatch(setEditAccountModalOpen(false))
        showToast('Account name updated')
      }),
    )
  }

  useEffect(() => {
    if (!currentAccount) return

    setName(currentAccount?.name)
  }, [currentAccount])

  const title = isAccountLoading ? 'Uploading Account image...' : 'Update Account image'

  return (
    <Modal
      className="edit-account-modal"
      open={!!isEditAccountModalOpen}
      onCancel={() => dispatch(setEditAccountModalOpen(false))}
      footer={null}
    >
      <div className="container">
        <div className="header">
          <h3 className="title">Edit account</h3>
        </div>

        <div className="account-info">
          <div className="account-image-container">
            {currentAccount?.image_url && (
              <img className="account-image" src={currentAccount?.image_url} alt={currentAccount?.name} />
            )}

            <div className="upload-image-button-container">
              <ImgCrop
                fillColor={'#f0f5ff'}
                modalTitle={title}
                modalWidth={500}
                onModalOk={(file) =>
                  dispatch(updateAccountImage(currentAccount?.id, file, () => dispatch(setEditAccountModalOpen(false))))
                }
              >
                <Upload
                  className="image-uploader"
                  maxCount={1}
                  showUploadList={{ showPreviewIcon: false }}
                  beforeUpload={beforeUpload}
                  customRequest={() => {}}
                >
                  <Tooltip title={title}>
                    <Button
                      className="icon-button"
                      type="text"
                      icon={<UploadOutlined />}
                      loading={isAccountLoading}
                      disabled={isAccountLoading}
                    />
                  </Tooltip>
                </Upload>
              </ImgCrop>
            </div>

            {currentAccount?.image_url && (
              <img className="account-image" src={currentAccount?.image_url} alt={currentAccount?.name} />
            )}
          </div>

          <div className="info">
            <p className="title">Account name</p>

            <Input
              size="large"
              value={name}
              placeholder="Account name"
              onChange={(evt) => {
                const value = evt.target.value
                const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1)

                setName(capitalizedValue)
              }}
              disabled={isAccountLoading}
            />
          </div>
        </div>

        <div className="actions">
          <Button
            className="submit-button"
            type="secondary"
            onClick={handleUpdateAccount}
            loading={isAccountLoading}
            disabled={!name}
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default EditAccountModal
