import { Layout as AntLayout } from 'antd'
import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Layout = styled(AntLayout)`
  height: 100dvh;
  background-color: ${(props) => props.theme.colors.gray4};
  overflow-y: auto;

  .whitelabel-logo {
    width: 124px;
    position: absolute;
    bottom: 16px;
    right: 16px;
    opacity: 0.25;
    user-select: none;
    pointer-events: none;
    z-index: 0;

    @media ${device.lgDown} {
      width: 84px;
      bottom: 12px;
      right: 12px;
    }
  }
`

export const Content = styled(Layout.Content)`
  width: 100%;
  display: flex;
`

export const ChildrenContent = styled(Layout.Content)`
  display: flex;
  align-items: flex-start;
  overflow: auto;
`
