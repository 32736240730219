import { useSelector, useDispatch } from 'react-redux'
import { Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { PeopleTeam24Regular, PersonProhibited24Regular } from '@fluentui/react-icons'
import TeamItem from '@/pages/MainPage/components/TeamItem'
import { setFilteredTeam } from '@/store/accounts/actions'
import { Container } from './styles'

const TeamsDropdown = ({ label = 'members', showAllOption, showNotInTeamsOption }) => {
  const dispatch = useDispatch()

  const { teamsData, filteredTeam } = useSelector((state) => state.accounts)

  const dropdownItems = [
    ...(showAllOption
      ? [
          {
            key: 'all',
            value: 'all',
            label: <TeamItem size="xsmall" icon={<PeopleTeam24Regular />} text={`All ${label}`} />,
          },
        ]
      : []),
    ...(showNotInTeamsOption
      ? [
          {
            key: 'not-in-teams',
            value: 'not-in-teams',
            label: <TeamItem size="xsmall" icon={<PersonProhibited24Regular />} text={`${label} without team`} />,
          },
        ]
      : []),
    ...(teamsData?.results
      ? teamsData.results.map((t) => ({
          key: t.id,
          value: t.id,
          label: <TeamItem team={t} size="xsmall" />,
        }))
      : []),
  ]

  const handleClick = (value) => {
    dispatch(setFilteredTeam(value?.key))
  }

  return (
    <Container className="teams-dropdown-container">
      <Dropdown
        overlayClassName="teams-dropdown"
        menu={{
          items: dropdownItems,
          onClick: handleClick,
        }}
        trigger={['click']}
      >
        <div className="content">
          <div className="selected-value">
            {filteredTeam ? dropdownItems?.find((i) => i.key === filteredTeam)?.label : dropdownItems[0].label}
          </div>

          <DownOutlined className="icon" />
        </div>
      </Dropdown>
    </Container>
  )
}

export default TeamsDropdown
