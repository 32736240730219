import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;

  .header-container {
    height: 120px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 20px;
  }

  > .main-content {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 20px;
    overflow: auto;
    padding: 24px;

    .row {
      width: 100%;
      display: flex;
      gap: 24px;
      flex: 1;

      &.no-grow {
        flex: initial;
      }
    }

    .col {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      flex: 1;
      overflow: auto;

      &.col-33 {
        flex: 1 1 33%;
      }

      &.col-66 {
        flex: 1 1 66%;
      }
    }

    .card {
      min-height: 215px;
      width: 100%;
      flex: 1;
    }
  }

  .show-lg-down {
    display: none !important;
  }

  @media ${device.xlDown} {
    > .main-content {
      .col {
        &.col-66 {
          flex: 1 1 50%;

          .row {
            flex-direction: column;
          }
        }

        &.col-33 {
          flex: 1 1 50%;
        }
      }
    }
  }

  @media ${device.lgDown} {
    > .main-content {
      height: initial;
      flex-direction: column;
      padding: 16px;

      .col {
        overflow: initial;
        gap: 16px;
      }

      .row {
        display: flex;
        flex-direction: column;
        flex: initial;
        overflow: visible;
      }

      .card {
        > .content {
          padding: 20px 16px;

          .licenses-info {
            padding: 0px;
          }
        }
      }
    }

    .show-lg-down {
      display: flex !important;
    }

    .hide-lg-down {
      display: none !important;
    }
  }
`
