import styled from 'styled-components'
import LearnImage from '@/assets/images/modules/learn.png'
import PracticeImage from '@/assets/images/modules/practice.png'
import AssessmentImage from '@/assets/images/modules/assessment.png'

export const SImage = styled.img`
  filter: ${localStorage.getItem('dw-theme') === 'dark' ? 'brightness(90%)' : 'brightness(100%)'};
`

const renderModuleImage = (module) => {
  if (module?.svg_icon_url) return <SImage src={module?.svg_icon_url} className="module-image" alt={module?.name} />
  if (module?.module_svg_icon_url)
    return <SImage src={module?.module_svg_icon_url} className="module-image" alt={module?.name} />

  if (module?.module_type === 'learn') return <SImage src={LearnImage} className="module-image" alt={module?.name} />
  if (module?.module_type === 'assessment')
    return <SImage src={AssessmentImage} className="module-image" alt={module?.name} />

  return <SImage src={PracticeImage} className="module-image" alt={module?.name} />
}

export { renderModuleImage }
