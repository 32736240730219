import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Select, Input } from 'antd'
import Button from '@/components/Button'
import { toggleReportIssueModal } from '@/store/app/actions'
import { createIssue } from '@/store/issues/actions'
import { Modal } from './styles'

const ReportIssueModal = () => {
  const dispatch = useDispatch()

  const { isReportIssueModalOpen } = useSelector((state) => state.app)

  const [isLoading, setIsLoading] = useState(false)
  const [issueType, setIssueType] = useState(null)
  const [description, setDescription] = useState('')

  const cleanup = () => {
    setIssueType(null)
    setDescription('')
    dispatch(toggleReportIssueModal(false))
  }

  const handleSubmit = async () => {
    const issueData = {
      issueType: issueType || 'content_issue',
      description,
    }

    setIsLoading(true)
    await dispatch(createIssue(issueData, cleanup))
    setIsLoading(false)
  }

  return (
    <Modal title={'Report issue'} open={isReportIssueModalOpen} onCancel={cleanup} footer={null} destroyOnClose>
      <div>
        <p>Let us know what issue you've found so we can fix it as soon as possible.</p>

        <Select
          value={issueType}
          placeholder="What is the issue about?"
          style={{ width: '100%', margin: '0 0 10px' }}
          dropdownStyle={{ zIndex: 9999 }}
          onChange={(e) => setIssueType(e)}
        >
          <Select.Option value="platform_issue">Platform is broken (errors, bugs, etc)</Select.Option>
          <Select.Option value="layout_issue">Layout, accessibility, look and feel</Select.Option>
          <Select.Option value="other">Other</Select.Option>
        </Select>

        <Input.TextArea
          rows={4}
          placeholder="Describe the issue and how to reproduce it."
          maxLength={480}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ marginBottom: 10 }}
        />

        <div className="actions">
          <p style={{ fontSize: 11 }}>
            Are you having a different issue? Please{' '}
            <a href="mailto:support@datawars.io?subject=Report an issue" target="_blank" rel="noreferrer">
              contact support
            </a>
            .
          </p>

          <Button type="primary" onClick={handleSubmit} loading={isLoading} disabled={!description || isLoading}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ReportIssueModal
