import styled, { css } from 'styled-components'
import TeamsEmptyState from '@/assets/images/empty-state-teams.png'
import TeamsEmptyStateDark from '@/assets/images/empty-state-teams-dark.png'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.gray1};
  border-radius: 10px;
  overflow: hidden;

  > .loading-content {
    padding: 20px 30px 10px;

    .teams-table {
      .ant-skeleton-paragraph li {
        width: 100% !important;
        height: 148px;
      }
    }
  }

  > .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    padding: 28px;

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-container {
        display: flex;
        align-items: center;
        gap: 8px;

        .icon svg {
          height: 20px;
          width: 20px;
        }

        .title {
          font-size: 16px;
          margin: 0px;
        }
      }

      .text {
        color: ${(props) => props.theme.colors.gray7};
        font-weight: 400;
        margin: 6px 0px 0px;
      }
    }

    .table-container {
      height: 100%;
      position: relative;
      overflow: hidden;

      .teams-table-container {
        overflow: hidden;

        .teams-table {
          overflow: hidden;
        }
      }

      .table-overlay {
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, ${(props) => props.theme.colors.gray1} 0%, transparent);
        position: absolute;
        bottom: 0px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 10px;
        z-index: 2;

        .cta-button {
          font-weight: 500;

          > span {
            text-decoration: underline;
          }
        }
      }
    }

    &.empty-state {
      ${() =>
        localStorage.getItem('dw-theme') === 'dark'
          ? css`
              background: url(${TeamsEmptyStateDark}) no-repeat center top;
            `
          : css`
              background: url(${TeamsEmptyState}) no-repeat center center;
            `}

      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 28px;

      > .header {
        display: flex;
        flex-direction: column;
        align-items: center;

        .container {
          text-align: center;

          .title-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .icon {
              color: ${(props) => props.theme.colors.geekblue5};

              svg {
                width: 24px;
                height: 24px;
              }
            }

            .title {
              font-size: 20px;
              line-height: 28px;
              margin: 0px;
            }
          }

          > button {
            margin-top: 16px;
          }
        }
      }
    }
  }
`
