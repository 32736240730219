import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const getSessions = async (params) => {
  const route = 'sessions'
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export { getSessions }
