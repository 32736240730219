import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { InstantSearch, SearchBox, Configure, useInstantSearch, useConfigure } from 'react-instantsearch'
import { SearchOutlined } from '@ant-design/icons'
import { getAlgoliaSearchClient } from '@/utils/algolia'
import searchParameters from './searchParameters'
import Filters from './components/Filters'
import Results from './components/Results'
import { Container } from './styles'

const TableContent = ({ algoliaSearchClient }) => {
  const { results, status, indexUiState } = useInstantSearch()

  const { pageSize: defaultPageSize } = useSelector((state) => state.app)

  const currentSearchParameters = indexUiState?.configure
  const { refine } = useConfigure({ ...currentSearchParameters })

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [statusFilter, setStatusFilter] = useState('all')

  const clearAlgoliaCache = () => {
    algoliaSearchClient.clearCache()
  }

  useEffect(() => {
    clearAlgoliaCache()
    setPage(1)

    let filter = 'status:active'
    if (statusFilter === 'archived') {
      filter = 'status:archived'
    }
    if (statusFilter === 'favorites') {
      filter += ' AND is_favorite:true'
    }

    refine({
      ...currentSearchParameters,
      page: 0,
      hitsPerPage: pageSize,
      filters: filter,
    })
  }, [statusFilter])

  useEffect(() => {
    let filter = 'status:active'
    if (statusFilter === 'archived') {
      filter = 'status:archived'
    }
    if (statusFilter === 'favorites') {
      filter += ' AND is_favorite:true'
    }

    refine({
      ...currentSearchParameters,
      page: page - 1,
      hitsPerPage: pageSize,
      filters: filter,
    })
  }, [page, pageSize])

  return (
    <div className="table-content">
      <div className="table-header">
        <div className="search-box">
          <SearchBox placeholder="Search account..." />

          <div className="search-icon-container">
            <SearchOutlined />
          </div>
        </div>

        <Filters statusFilter={statusFilter} setStatusFilter={setStatusFilter} />
      </div>

      <Results
        results={results}
        status={status}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </div>
  )
}

const SuperAccountOwnerAccountsTable = () => {
  const { userProfile } = useSelector((state) => state.users)

  const algoliaSearchClient = getAlgoliaSearchClient(userProfile?.config?.algolia_secret_api_key)

  return (
    <Container className="super-account-owner-accounts-table-container">
      <div className="header-container">
        <h4 className="title">Accounts selector</h4>
      </div>

      <InstantSearch
        searchClient={algoliaSearchClient}
        indexName={import.meta.env.REACT_APP_ALGOLIA_ACCOUNTS_INDEX}
        // FIXME: be ready for next Algolia update
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure {...searchParameters} />

        <TableContent algoliaSearchClient={algoliaSearchClient} />
      </InstantSearch>
    </Container>
  )
}

export default SuperAccountOwnerAccountsTable
