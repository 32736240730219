import { Skeleton } from 'antd'
import CodeActivity from './components/CodeActivity'
import InputActivity from './components/InputActivity'
import MultipleChoiceActivity from './components/MultipleChoiceActivity'
import { Container } from './styles'

const Activity = ({ activity, isReview }) => {
  const isActivitySubmitted = !!activity?.user_status
  const isActivityPassed = activity?.user_status?.is_passed

  return (
    <Container
      id="activity-content-container"
      isReview={isReview}
      className={isActivitySubmitted ? (isActivityPassed ? 'correct' : 'incorrect') : ''}
    >
      <Skeleton active loading={!activity} title={false} paragraph={{ rows: 4 }} />

      {activity?.activity_type === 'code_validated' && <CodeActivity activity={activity} isReview={isReview} />}

      {activity?.activity_type === 'input' && <InputActivity activity={activity} isReview={isReview} />}

      {activity?.activity_type === 'multiple_choice' && (
        <MultipleChoiceActivity activity={activity} isReview={isReview} />
      )}
    </Container>
  )
}

export default Activity
