import packageJson from '../../package.json'

const version = packageJson.version
const currentEnv = import.meta.env.REACT_APP_ENVIRONMENT
const isProduction = currentEnv === 'production'
const isDevelopment = currentEnv === 'development'
const isStaging = currentEnv === 'staging'

const apiURL = import.meta.env.REACT_APP_API_BASE_URL
const platformURL = import.meta.env.REACT_APP_PLATFORM_BASE_URL
const profilesURL = import.meta.env.REACT_APP_PROFILES_BASE_URL
const djangoAdminURL = import.meta.env.REACT_APP_DJANGO_ADMIN_URL

export { version, currentEnv, isDevelopment, isStaging, isProduction, apiURL, platformURL, profilesURL, djangoAdminURL }
