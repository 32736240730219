import styled, { css } from 'styled-components'
import { Modal as AntModal } from 'antd'
import { device } from '@/themes/breakpoints'

export const Modal = styled(AntModal)`
  max-width: ${(props) => (props.inviteType === 'link' ? '560px' : '950px')};
  width: 100% !important;

  .ant-modal-content {
    padding: 0px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
      width: calc(100% - 64px);
      display: flex;
      align-items: stretch;
      gap: 30px;
      padding: 32px 32px 0px;

      ${(props) =>
        props.inviteType === 'link'
          ? css`
              flex-direction: column;

              > .main-content {
                width: 100%;

                > .header {
                  .title-container {
                    flex-direction: column;
                  }
                }
              }

              .emails-content {
                display: none;
              }
            `
          : css`
              flex-direction: row;

              > .main-content {
                width: 50%;

                > .header {
                  .title-container {
                    flex-direction: row;
                  }
                }
              }

              .emails-content {
                display: block;
              }
            `};

      > .main-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 1;

        > .header {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;
          margin: 28px 0px 0px;

          .title-container {
            display: flex;
            align-items: center;
            gap: 12px;

            .icon {
              width: 35px;
              height: 35px;
              color: ${(props) => props.theme.colors.geekblue5};
              font-size: 35px;

              &.success {
                color: ${(props) => props.theme.colors.cyan5};
              }

              &.error {
                color: ${(props) => props.theme.colors.red6};
              }
            }

            .title {
              font-weight: 400;
              text-align: center;
              margin: 0px;
            }
          }

          .text {
            max-width: 300px;
            color: ${(props) => props.theme.colors.gray8};
            text-align: center;
            margin: 0px;
          }
        }

        .link-input {
          .copy-icon {
            width: 20px;
          }

          input {
            height: 40px;
            font-size: 14px;
          }
        }
      }

      .emails-content {
        width: 50%;
        flex: 1;
      }
    }

    .actions {
      width: 100%;
      gap: 10px;

      > .content {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        padding: 28px;

        .share-google-classroom-btn {
          .icon {
            width: 20px;
            display: flex;
          }
        }

        .button {
          display: flex;
          align-items: center;
          gap: 4px;

          .icon {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }

  .ant-modal-close {
    height: 40px;
    width: 40px;
    background-color: ${(props) => props.theme.colors.gray4};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 2px;

    .ant-modal-close-x {
      line-height: inherit;

      svg {
        color: ${(props) => props.theme.colors.gray10};
        font-size: 18px;
      }
    }
  }

  @media ${device.lgDown} {
    .ant-modal-body {
      .container {
        flex-direction: column;

        > .main-content {
          width: 100%;
        }

        .emails-content {
          width: 100%;

          .invite-emails-detail-container {
            max-height: initial;
          }
        }
      }
    }
  }
`
