import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 120px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: 0.3s all;

  > .main-content {
    height: 100%;
    display: flex;
    align-items: flex-start;
    gap: 22px;
    padding: 20px 48px 20px 20px;

    .image-container {
      min-width: 78px;
      max-width: 78px;
      min-height: 78px;
      max-height: 78px;
      background: linear-gradient(
        250deg,
        ${(props) => props.theme.colors.base.geekblue4} 0%,
        ${(props) => props.theme.colors.base.gray12} 100%
      );
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      .main-icon {
        width: 32px;
        height: 32px;
        color: ${(props) => props.theme.colors.base.gray1};
        font-size: 32px;
      }

      ${(props) =>
        props.playgroundBackgroundImage &&
        css`
          background-image: url(${(props) => props.playgroundBackgroundImage});
          background-size: cover;
          background-position: center;
          object-fit: cover;

          .main-icon {
            display: none;
          }
        `}
    }

    > .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 6px;

      .header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 6px;

        .title-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          .menu-item {
            > .title:hover {
              text-decoration: underline;
            }
          }

          .title {
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 0px;
          }

          .author-info {
            color: ${(props) => props.theme.colors.gray7};
            font-size: 12px;
            line-height: 20px;
            border: 1px solid ${(props) => props.theme.colors.gray4};
            border-radius: 8px;
            display: flex;
            align-items: center;
            align-self: flex-start;
            gap: 4px;
            white-space: nowrap;
            padding: 3px 6px;

            > span {
              color: ${(props) => props.theme.colors.gray13};
            }
          }

          .open-options-button {
            color: ${(props) => props.theme.colors.gray8};
            position: absolute;
            top: 18px;
            right: 10px;
          }
        }

        .info-box {
          display: flex;
          align-items: center;
          gap: 10px;

          .members-status-container {
            display: flex;
            gap: 10px;

            .stats-container {
              display: flex;
              align-items: center;
              gap: 10px;

              .item {
                display: flex;
                align-items: center;
                gap: 4px;
                color: ${(props) => props.theme.colors.gray6};
                font-size: 12px;
                font-weight: 500;

                svg {
                  height: 16px;
                  width: 16px;
                }
              }
            }

            .assigned-tag {
              background-color: ${(props) => props.theme.colors.gray2};
              color: ${(props) => props.theme.colors.gray7};
              border: 1px solid ${(props) => props.theme.colors.gray5};
              margin: 0px;
            }
          }
        }
      }
    }
  }

  &.is-loading {
    .module-content {
      display: flex;
      flex-direction: row;
      gap: 16px;
      padding: 0px 20px;
    }

    .left-side {
      width: 80px;
      display: flex;
      align-items: center;

      .ant-skeleton-avatar {
        width: 80px;
        height: 80px;
        border-radius: 16px;
      }
    }

    .right-side {
      display: flex;
      align-items: center;
    }
  }

  @media ${device.smDown} {
    > .main-content {
      gap: 14px;
      padding: 24px 16px 16px;

      .image-container {
        min-width: 54px;
        max-width: 54px;
        min-height: 54px;
        max-height: 54px;

        .main-icon {
          width: 24px;
          height: 24px;
          font-size: 24px;
        }
      }

      > .content {
        gap: 2px;

        .header {
          .title-container {
            .title {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
`
