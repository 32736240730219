import dayjs from 'dayjs'
import { Tooltip, Button as AntdButton } from 'antd'
import { BulbOutlined, EyeOutlined } from '@ant-design/icons'
import Button from '@/components/Button'
import HTMLBlock from '@/components/HTMLBlock'
import CodeBlock from '@/components/CodeBlock'
import ActivityReactions from '@/components/Activity/components/ActivityReactions'

const CodeActivity = ({ activity }) => {
  const answer = activity?.user_status?.submitted_answer?.code_answer

  const isActivitySubmitted = !!activity.user_status
  const answerSubmittedAt = activity.user_status?.submitted_answer_at
  const isActivityPassed = activity.user_status?.is_passed

  const solutionRequestedAt = activity.user_status?.solution_requested_at
  const isSolutionRequestedBeforeSubmission = dayjs(solutionRequestedAt).isBefore(dayjs(answerSubmittedAt))

  const hintRequestedAt = activity.user_status?.hint_requested_at
  const isHintRequestedBeforeSubmission = dayjs(hintRequestedAt).isBefore(dayjs(answerSubmittedAt))

  const activityActions = (
    <div className="activity-actions">
      {isHintRequestedBeforeSubmission && (
        <Tooltip title={'Hint was requested'}>
          <AntdButton className="hint-btn" shape="circle" size="small" icon={<BulbOutlined />} disabled />
        </Tooltip>
      )}

      {isSolutionRequestedBeforeSubmission && (
        <Tooltip title={'Solution was requested'}>
          <AntdButton className="solution-btn" shape="circle" size="small" icon={<EyeOutlined />} disabled />
        </Tooltip>
      )}
    </div>
  )

  return (
    <>
      <div id={`activity-${activity.id}`} className="activity-container code-activity">
        <div className="activity-content">
          <div className="activity-body">
            <span className="activity-number">{(activity?.order || 0) + 1}</span>

            {activity?.title_html && (
              <div className="title-container" dangerouslySetInnerHTML={{ __html: activity?.title_html }} />
            )}
          </div>

          <HTMLBlock content={activity?.content_html} className={'activity-content-markdown'} />
        </div>

        {isActivitySubmitted && answer && (
          <div className="submitted-box">
            <p className="text">Submitted answer:</p>

            <HTMLBlock content={<CodeBlock language={activity?.code_language}>{answer}</CodeBlock>} />
          </div>
        )}
      </div>

      <div className="review-status-block">
        {isActivitySubmitted ? (
          <Button type="dashed" className={`activity-status-btn ${isActivityPassed ? 'correct' : 'incorrect'}`}>
            {isActivityPassed ? 'Correct' : 'Incorrect'}
          </Button>
        ) : (
          <Button type="dashed" className={'activity-status-btn not-actioned'}>
            Not actioned
          </Button>
        )}

        {activityActions}

        {isActivitySubmitted && <ActivityReactions activity={activity} />}
      </div>
    </>
  )
}

export default CodeActivity
