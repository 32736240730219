import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentModule: null,
  isModuleLoading: false,
  isAssignModalOpen: false,
  error: null,
}

const slice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    SET_MODULE_LOADING: (state, { payload }) => {
      state.isModuleLoading = payload
    },
    MODULE_UPDATED: (state, { payload }) => {
      state.currentModule = payload
      state.isModuleLoading = false
      state.error = null
    },
    SET_ASSIGN_MODAL_OPEN: (state, { payload }) => {
      state.isAssignModalOpen = payload
    },
    RESET_MODULES_STATE: () => initialState,
    SET_MODULE_ERROR: (state, { payload }) => {
      state.error = payload
      state.isModuleLoading = false
    },
  },
})

export const { SET_MODULE_LOADING, MODULE_UPDATED, SET_ASSIGN_MODAL_OPEN, RESET_MODULES_STATE, SET_MODULE_ERROR } =
  slice.actions
export default slice.reducer
