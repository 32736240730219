import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  padding: 0px 0px 10px;

  .content {
    padding: 0px 10px;

    .no-results-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 20px;

      & > img {
        user-select: none;
        pointer-events: none;
      }

      .title {
        margin-bottom: 6px;
      }

      .text {
        color: ${(props) => props.theme.colors.gray7};
        font-weight: 500;
      }
    }
  }

  .results-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0px -10px;

    .module-container {
      max-width: 50%;
      flex: 0 50%;
      padding: 10px;
    }

    .pagination-container {
      width: 100%;
      bottom: 0px;
      padding: 20px 0px 0px;
      z-index: 1;

      .ant-pagination {
        /* display: flex;
        justify-content: flex-end; */
      }
    }
  }

  @media ${device.xlDown} {
    .results-container {
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 16px;

      .module-container {
        max-width: 100%;
        flex: 1;
        padding: 0px;
      }
    }
  }
`
