import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Menu, Modal, Dropdown, Switch, Tooltip } from 'antd'
import { ExclamationCircleOutlined, MoonOutlined, SunOutlined, LogoutOutlined, FlagOutlined } from '@ant-design/icons'
import { ArrowRepeatAll24Regular } from '@fluentui/react-icons'
import { platformURL } from '@/helpers/env'
import { capitalize } from '@/utils/functions'
import Button from '@/components/Button'
import ProfilePicture from '@/components/ProfilePicture'
import Logo from '@/assets/images/logo-sm.svg'
import LogoWhite from '@/assets/images/logo-sm-white.svg'
import MenuIcon from '@/assets/images/menu-icon.svg'
import { setAppTheme, toggleReportIssueModal } from '@/store/app/actions'
import { logout } from '@/store/users/actions'
import { Container } from './styles'

const Navbar = ({ isLoading }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { theme } = useSelector((state) => state.app)

  const { isAuthenticated, userProfile } = useSelector((state) => state.users)
  const canViewDarkMode = userProfile?.permissions?.includes('content.view_dark_mode')

  const handleLogout = () => {
    Modal.confirm({
      title: 'Sign out',
      content: 'Are you sure you want to log out?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, sign out',
      cancelText: 'Keep working',
      onOk: () => dispatch(logout()),
      okButtonProps: { danger: true, type: 'primary', disabled: isLoading, loading: isLoading },
    })
  }

  const rightMenuItems = [
    isAuthenticated
      ? {
          key: 'profile',
          label: (
            <Dropdown
              trigger={['click']}
              overlayClassName={'navbar-right-menu'}
              menu={{
                items: [
                  {
                    key: 'reportIssue',
                    label: (
                      <a className="menu-item" onClick={() => dispatch(toggleReportIssueModal(true))}>
                        <FlagOutlined /> Report issue
                      </a>
                    ),
                  },
                  {
                    key: 'signOut',
                    label: (
                      <a className="menu-item" onClick={handleLogout}>
                        <LogoutOutlined /> Sign out
                      </a>
                    ),
                  },
                ],
              }}
            >
              <span style={{ display: 'flex', padding: '0px 8px' }}>
                <ProfilePicture
                  src={userProfile?.avatar_url}
                  alt={capitalize(userProfile?.first_name)}
                  isTeams={true}
                />
                <span style={{ textTransform: 'capitalize' }}>{userProfile?.first_name}</span>
              </span>
            </Dropdown>
          ),
        }
      : {},
  ]

  const rightMenuMobileItems = [
    ...(isAuthenticated
      ? [
          {
            key: 'reportIssue',
            label: (
              <a onClick={() => dispatch(toggleReportIssueModal(true))} className="mobile-menu-item">
                <FlagOutlined /> Report issue
              </a>
            ),
          },
        ]
      : []),
    {
      key: 'teams',
      label: (
        <Link to={platformURL} className="mobile-menu-item">
          <ArrowRepeatAll24Regular /> Go to Learning platform
        </Link>
      ),
    },
    {
      key: 'signout',
      label: (
        <a onClick={handleLogout} className="mobile-menu-item">
          <LogoutOutlined /> Sign out
        </a>
      ),
    },
  ]

  const [lastScrollPos, setLastScrollPos] = useState(0)

  const handleScroll = (evt) => {
    const scrollPosition = evt.target.scrollTop
    const elem = document.getElementsByClassName('navbar-container')?.[0]
    const progress = (evt.target.scrollTop / (evt.target.scrollHeight - window.innerHeight + 64)) * 100

    const scrollPos = lastScrollPos < scrollPosition ? 'down' : 'up'
    // const isHidden = elem.classList.contains('hidden')

    if (scrollPos === 'down') {
      elem.classList.add('hidden')
    }

    if (scrollPos === 'up') {
      elem.classList.remove('hidden')
    }

    if (progress < 5) {
      elem.classList.remove('hidden')
    }

    if (progress > 95) {
      elem.classList.add('hidden')
    }

    setLastScrollPos(scrollPosition)
  }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, true)

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll, true)
  //   }
  // }, [lastScrollPos])

  useEffect(() => {
    const elem = document.getElementsByClassName('navbar-container')?.[0]
    elem.classList.remove('hidden')
  }, [location])

  return (
    <Container className="navbar-container">
      <div className="content">
        <div className="left-content">
          <Link to="/">
            <div className="logo-container">
              <img className="logo" src={theme === 'dark' ? LogoWhite : Logo} alt="logo" />
              <p className="logo-text">Teams</p>
            </div>
          </Link>
        </div>

        <div className="right-content">
          {canViewDarkMode && (
            <Tooltip title={theme === 'dark' ? 'Turn lights on' : 'Turn lights off'}>
              <Switch
                className="theme-switch"
                checkedChildren={<MoonOutlined />}
                unCheckedChildren={<SunOutlined />}
                size="small"
                checked={theme !== 'dark'}
                onChange={(isDark) => dispatch(setAppTheme(isDark ? 'light' : 'dark'))}
              />
            </Tooltip>
          )}
          <Tooltip title={'Go to Learning platform'}>
            <Button
              className="navbar-btn"
              type="text"
              icon={
                <Link to={platformURL}>
                  <ArrowRepeatAll24Regular />
                </Link>
              }
            />
          </Tooltip>
          <Menu id="navbar-right-menu" mode="horizontal" items={rightMenuItems} disabledOverflow />
          <Dropdown
            menu={{
              items: rightMenuMobileItems,
            }}
            trigger={['click']}
            overlayClassName={'navbar-right-mobile-menu'}
          >
            <span className="menu-icon">
              <img className="menu-icon-img" src={MenuIcon} alt="menu" />
            </span>
          </Dropdown>
        </div>
      </div>
    </Container>
  )
}

export default Navbar
