import styled from 'styled-components'

export const Container = styled.div`
  max-height: 425px;
  height: calc(100% - 32px);
  background-color: ${(props) => props.theme.colors.gray2};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 16px;

  .loading-content,
  .empty-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ant-spin {
      margin-bottom: 14px;
      margin-left: -24px;
    }

    .loading-text {
      color: ${(props) => props.theme.colors.gray7};
      margin: 0px;
    }
  }

  > .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;

    .emails-container {
      height: 100%;
      overflow: auto;

      .ant-table {
        font-size: 13px;

        .email-col {
          max-width: 250px;
          overflow: auto;
        }

        .license-col {
          min-width: inherit;
        }

        .team-col {
          min-width: inherit;

          .team-select {
            width: 52px;

            .ant-select-selector {
              background-color: transparent;
              font-size: 13px;
              padding-left: 4px;
              padding-right: 8px;

              .team-item-container {
                gap: 6px;

                .team-icon {
                  min-height: 16px;
                  max-height: 16px;
                  min-width: 16px;
                  max-width: 16px;

                  svg {
                    width: 14px;
                    height: 14px;
                  }
                }

                .name {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .errors-container {
      width: 100%;
      flex: 1;

      .ant-collapse {
        background-color: ${(props) => props.theme.colors.red1};
        border: 1px solid ${(props) => props.theme.colors.red3};
        overflow: auto;

        .ant-collapse-header {
          justify-content: center;
          padding: 8px;

          .ant-collapse-expand-icon {
            height: 18px;
            color: ${(props) => props.theme.colors.red5};
          }

          .ant-collapse-header-text {
            color: ${(props) => props.theme.colors.red5};
            flex: none;
            margin: 0px;
            font-size: 12px;
          }
        }

        .ant-collapse-content {
          max-height: 180px;
          overflow: auto;
        }
      }

      .emails-list {
        padding-left: 12px;
        margin: 0px;

        .email {
          color: ${(props) => props.theme.colors.red5};
          font-size: 12px;

          .highlight {
            font-weight: 600;
          }
        }
      }
    }
  }
`
