import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  min-height: 52px;
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;

  > .event-content {
    width: 100%;
    display: flex;
    gap: 14px;

    .icon-container {
      min-width: 38px;
      max-width: 38px;
      min-height: 38px;
      max-height: 38px;
      background-color: ${(props) => props.theme.colors.gray3};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.success {
        background-color: ${(props) => props.theme.colors.gold1};
      }

      .icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
        color: ${(props) => props.theme.colors.gray12};

        &.emoji {
          margin-bottom: 8px;
        }
      }
    }

    .event-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;

      .event-summary {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 3px 0px;

        .member-avatar {
          .profile-picture {
            margin-right: 6px;
          }

          .name-container {
            .name {
              font-weight: 500;
            }
          }
        }

        .text {
          color: ${(props) => props.theme.colors.gray7};
          font-weight: 500;
        }
      }

      .comment-content-container {
        background-color: ${(props) => props.theme.colors.gray2};
        position: relative;
        margin-bottom: 12px;
        border-radius: 2px;

        .mdxeditor {
          width: calc(100% - 2px);
        }

        .comment-content {
          padding: 12px;
        }

        .comment-actions {
          position: absolute;
          right: 16px;
          bottom: 4px;

          .icon {
            color: ${(props) => props.theme.colors.gray8};
          }
        }

        .more-button {
          position: absolute;
          top: 8px;
          right: 8px;
        }
      }
    }
  }

  .divider {
    width: 1px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.gray5}aa;
    position: absolute;
    left: 19px;
    top: 41px;
  }

  &:last-child {
    .divider {
      display: none;
    }
  }

  @media ${device.mdDown} {
    > .content {
      gap: 8px;

      .event-content {
        .event-summary {
          flex-direction: column;
          align-items: flex-start;
          gap: 2px;
        }
      }
    }
  }
`
