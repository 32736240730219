import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PlusSquareOutlined, CloseSquareOutlined } from '@ant-design/icons'
import { PeopleTeamAdd24Regular, PeopleTeamDelete24Regular } from '@fluentui/react-icons'
import { Table, Select } from 'antd'
import Button from '@/components/Button'
import MemberAvatar from '@/components/MemberAvatar'
import TeamItem from '@/pages/MainPage/components/TeamItem'
import { fetchAccountTeams, setAddToTeamModalOpen, addTeamUsers, removeTeamUsers } from '@/store/accounts/actions'
import { Modal } from './styles'

const AddToTeamModal = () => {
  const dispatch = useDispatch()

  const [teamId, setTeamId] = useState()

  const {
    currentAccount: account,
    teamsData,
    usersData,
    selectedUsers,
    isAddToTeamModalOpen,
    isTeamsLoading,
  } = useSelector((state) => state.accounts)

  const isAddModal = isAddToTeamModalOpen === 'add'
  const isRemoveModal = isAddToTeamModalOpen === 'remove'

  const selectedUsersData = usersData?.results?.filter((u) => selectedUsers?.includes(u?.user?.id))
  let uniqueTeams = selectedUsersData
    ?.map((u) => u.teams)
    ?.flat()
    ?.map((t) => JSON.stringify(t))
  uniqueTeams = [...new Set(uniqueTeams)]?.map((s) => JSON.parse(s))

  const availableTeams = isAddModal ? teamsData?.results : uniqueTeams

  const handleAddMembersToTeam = () => {
    if (isRemoveModal) {
      dispatch(
        removeTeamUsers(account?.id, teamId, { user_ids: selectedUsers }, () => dispatch(setAddToTeamModalOpen(false))),
      )
      return
    }

    if (isAddModal) {
      // only update users that don't are part of the selected team
      const auxUserIds = selectedUsersData
        ?.filter((user) => {
          const team = user?.teams?.find((t) => t.id === teamId)

          if (team) {
            return false
          }

          return true
        })
        ?.map((u) => u?.user?.id)

      dispatch(
        addTeamUsers(account?.id, teamId, { user_ids: auxUserIds }, () => dispatch(setAddToTeamModalOpen(false))),
      )
    }
  }

  useEffect(() => {
    if (!isAddToTeamModalOpen) {
      setTeamId(null)
    }
  }, [isAddToTeamModalOpen])

  useEffect(() => {
    if (!account || teamsData) return

    // FIXME: won't display more than 20 teams
    dispatch(fetchAccountTeams(account?.id))
  }, [account])

  return (
    <Modal
      className="team-modal"
      open={!!isAddToTeamModalOpen}
      onCancel={() => dispatch(setAddToTeamModalOpen(false))}
      footer={null}
    >
      <div className="container">
        <div className="header">
          {isAddModal ? (
            <PeopleTeamAdd24Regular className="icon" />
          ) : (
            <PeopleTeamDelete24Regular className="icon error" />
          )}
          <h3 className="title">{isAddModal ? 'Add to team' : 'Remove from team'}</h3>
          <p className="text">
            The following members will be {isAddModal ? 'added to' : 'removed from'} the team you select below.
          </p>
        </div>

        <div className="content">
          <div className="section">
            <div className="section">
              <Table
                className="members-table"
                size={'small'}
                columns={[
                  {
                    title: 'Member',
                    dataIndex: 'member',
                  },
                ]}
                dataSource={selectedUsersData?.map((userData) => ({
                  key: userData?.user?.id,
                  member: (
                    <MemberAvatar
                      lastName={userData?.user?.last_name}
                      firstName={userData?.user?.first_name}
                      showName
                      avatarUrl={userData?.user?.avatar_url}
                    />
                  ),
                }))}
                showHeader={false}
                pagination={false}
              />
            </div>
          </div>

          <div className="section">
            <p className="section-title">Choose a team</p>

            <div className="section">
              <Select
                className="team-select"
                value={teamId}
                placeholder="Select a team"
                options={availableTeams?.map((t) => ({
                  value: t.id,
                  label: <TeamItem team={t} size="xsmall" />,
                }))}
                onChange={setTeamId}
                allowClear
                virtual={false}
              />
            </div>
          </div>

          <div className="actions">
            <Button
              className="submit-button"
              type={isAddModal ? 'secondary' : 'primary'}
              danger={!isAddModal}
              onClick={handleAddMembersToTeam}
              loading={isTeamsLoading}
              disabled={!teamId}
            >
              {isAddModal ? <PlusSquareOutlined /> : <CloseSquareOutlined />} {isAddModal ? 'Add to' : 'Remove from'}{' '}
              team
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddToTeamModal
