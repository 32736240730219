import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Steps, Form, Input, Select } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { getUtmTagsFromUrl } from '@/helpers/getUtmTagsFromUrl'
import { getEmailRules } from '@/helpers/formValidation'
import accountSizes from '@/constants/accountSizes'
import industries from '@/constants/industries'
import educationLevels from '@/constants/educationLevels'
import Button from '@/components/Button'
import { createAccount } from '@/store/accounts/actions'
import { Container } from '../AccountCreationContent/styles'

const CreateAccountForm = ({ orgType, goToAccountTypeSelector }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { handleSubmit, control, watch } = useForm()

  const { isLoading } = useSelector((state) => state.accounts)

  const [currentStep, setCurrentStep] = useState(0)
  const [errors, setErrors] = useState({})

  const isBusiness = orgType === 'business'
  const isEducation = orgType === 'education'

  const handleBack = () => {
    if (currentStep === 1) {
      setCurrentStep(0)
      return
    }

    if (goToAccountTypeSelector) {
      goToAccountTypeSelector()
    }
  }

  const onSubmit = async (data) => {
    setErrors({})

    if (currentStep === 0) {
      setCurrentStep(1)
      return
    }

    const auxData = {
      name: data?.name,
      metadata: {
        type: orgType,
        email: data?.email,
        size: data?.size,
        reason: data?.reason !== 'other' ? data?.reason : data?.otherReason,
        role: data?.ownerRole,
        // business-only fields
        ...(isBusiness
          ? {
              industry: data?.industry,
            }
          : {}),
        // education-only fields
        ...(isEducation
          ? {
              education_level: data?.educationLevel,
              class_name: data?.class,
            }
          : {}),

        ...getUtmTagsFromUrl(),
      },
    }

    await dispatch(createAccount(auxData, (account) => navigate(`/accounts/${account.id}`, { replace: true })))
  }

  const onError = (errors) => {
    if (currentStep === 0) {
      const stepFields = ['name', 'email', 'size', 'industry', 'educationLevel']
      const stepErrors = stepFields.map((field) => errors[field]).filter((error) => error)
      setErrors(stepErrors)

      if (!stepErrors.length) {
        setCurrentStep(1)
        return
      }
    }

    if (currentStep === 1) {
      const stepFields = ['ownerRole', 'class', 'reason', 'otherReason']
      const stepErrors = stepFields.map((field) => errors[field]).filter((error) => error)
      setErrors(stepErrors)
    }
  }

  const validateWhiteSpace = (value) => {
    if (value.trim() === '') {
      return 'Input cannot be only blank spaces'
    }
    return true
  }

  return (
    <Container id="create-account-form">
      <div className="steps-container">
        <Steps size="small" current={currentStep} items={[{}, {}]} />
      </div>

      <div className="header">
        <h4 className="title">
          {isBusiness ? 'Create a free account for your team' : 'Create a free education account'}
        </h4>

        {currentStep === 0 && (
          <h5 className="subtitle">{isBusiness ? "Enter your company's details" : "Enter your school's details"}</h5>
        )}

        {currentStep === 1 && <h5 className="subtitle">Last step, tell us more about you</h5>}
      </div>

      <Form className="form-container" onFinish={handleSubmit(onSubmit, onError)} autoComplete="off">
        <div className="form-body">
          <div className={`step ${currentStep !== 0 ? 'hidden' : ''}`}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: `${isBusiness ? 'Company' : 'School'} name is required`,
                minLength: 2,
                validate: validateWhiteSpace,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  size="large"
                  status={errors.name ? 'error' : null}
                  placeholder={`${isBusiness ? 'Company' : 'School'} name`}
                  rules={[{}]}
                  onChange={(evt) => {
                    const value = evt.target.value
                    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1)
                    evt.target.value = capitalizedValue

                    field.onChange(evt)
                  }}
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              rules={getEmailRules(true)}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  size="large"
                  status={errors.email ? 'error' : null}
                  placeholder={`${isBusiness ? 'Company' : 'School'} email`}
                />
              )}
            />

            <Controller
              name="size"
              control={control}
              rules={{ required: `${isBusiness ? 'Team size' : 'Size of your class'} is required` }}
              render={({ field }) => (
                <Select
                  {...field}
                  size="large"
                  status={errors.size ? 'error' : null}
                  placeholder={isBusiness ? 'Team size' : 'Size of your class'}
                  options={accountSizes}
                  virtual={false}
                />
              )}
            />

            {isBusiness && (
              <Controller
                name="industry"
                control={control}
                rules={{ required: 'Industry is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    size="large"
                    status={errors.industry ? 'error' : null}
                    placeholder="Industry"
                    options={industries}
                    virtual={false}
                  />
                )}
              />
            )}

            {isEducation && (
              <Controller
                name="educationLevel"
                control={control}
                rules={{ required: 'Education level is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    size="large"
                    status={errors.industry ? 'error' : null}
                    placeholder="Education level"
                    options={educationLevels}
                    virtual={false}
                  />
                )}
              />
            )}
          </div>

          <div className={`step ${currentStep !== 1 ? 'hidden' : ''}`}>
            <Controller
              name="ownerRole"
              control={control}
              rules={{ required: 'Your role is required', minLength: 2, validate: validateWhiteSpace }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  size="large"
                  status={errors.ownerRole ? 'error' : null}
                  placeholder="Your role"
                  rules={[{}]}
                />
              )}
            />

            {isEducation && (
              <Controller
                name="class"
                control={control}
                rules={{ required: 'Class name is required', minLength: 2, validate: validateWhiteSpace }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    size="large"
                    status={errors.class ? 'error' : null}
                    placeholder="Class name"
                    rules={[{}]}
                  />
                )}
              />
            )}

            <Controller
              name="reason"
              control={control}
              rules={{ required: 'Reason is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  size="large"
                  status={errors.reason ? 'error' : null}
                  placeholder="What are you planning to use DataWars for?"
                  options={
                    isBusiness
                      ? [
                          { label: 'Upskill my team', value: 'upskill my team' },
                          {
                            label: 'Create a Skill Gap report of my team',
                            value: 'create a skill gap report of my team',
                          },
                          { label: 'Create assessments for hiring', value: 'create assessments for hiring' },
                          { label: 'Other', value: 'other' },
                        ]
                      : [
                          { label: 'Teach a live class', value: 'teach a live class' },
                          { label: 'Assign homework for my students', value: 'assign homework for my students' },
                          { label: 'Evaluate and create assessments', value: 'evaluate and create assessments' },
                          { label: 'Other', value: 'other' },
                        ]
                  }
                  virtual={false}
                />
              )}
            />

            {watch('reason') === 'other' && (
              <Controller
                name="otherReason"
                control={control}
                rules={{ required: 'Reason is required' }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    size="large"
                    status={errors.otherReason ? 'error' : null}
                    placeholder="What are you planning to use DataWars for?"
                    rules={[{}]}
                  />
                )}
              />
            )}
          </div>
        </div>

        {!!Object.keys(errors)?.length && (
          <div className="error-container">
            {errors && <p className="error-text">{errors[Object.keys(errors)?.[0]]?.message}</p>}
          </div>
        )}

        <div className="actions">
          {goToAccountTypeSelector || currentStep === 1 ? (
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleBack}>
              Back
            </Button>
          ) : (
            <span />
          )}

          <Button type="primary" htmlType="submit" loading={isLoading}>
            {currentStep === 0 ? 'Next' : 'Create'}
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default CreateAccountForm
