import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Skeleton } from 'antd'
import { LineChartOutlined } from '@ant-design/icons'
import { PersonArrowRight24Regular } from '@fluentui/react-icons'
import { Area } from '@ant-design/plots'
import Button from '@/components/Button'
import { fetchActivityAnalytics } from '@/store/accounts/actions'
import TeamsEmptyActivities from '@/assets/images/empty-state-activities.png'
import TeamsEmptyActivitiesDark from '@/assets/images/empty-state-activities-dark.png'
import { Container } from './styles'

const ActivitiesCard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { accountId } = useParams()

  const { theme } = useSelector((state) => state.app)
  const {
    currentAccount: account,
    activityAnalytics,
    isLoading: isAccountsLoading,
  } = useSelector((state) => state.accounts)

  const data = activityAnalytics?.data
    ? activityAnalytics?.data?.map((data) => ({
        date: data?.dt,
        Activities: data?.series?.[0],
      }))
    : []

  const config = {
    data,
    xField: (d) => dayjs(d.date).format('MMM D'),
    yField: 'Activities',
    height: 174,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 10,
    marginBottom: 10,
    shapeField: 'smooth',
    axis: {
      x: {
        tickLength: 10,
        tickCount: 10,
        tickLineWidth: 0,
        labelAutoRotate: false,
        labelAutoHide: true,
        labelFill: localStorage.getItem('dw-theme') === 'dark' ? '#ffffff' : '#000000',
      },
      y: {
        tickLength: 10,
        tickCount: 3,
        tickLineWidth: 0,
        grid: true,
        gridLineDash: 'line',
        gridStrokeOpacity: 0.1,
      },
    },
    animate: {
      enter: {
        type: 'pathIn',
      },
    },
    interaction: {
      tooltip: true,
    },
    line: {
      style: {
        stroke: '#1890ff',
        strokeWidth: 2,
      },
      shapeField: 'smooth',
      tooltip: true,
    },
    style: {
      maxWidth: 30,
      fill: `l(270) 0:#ffffff00 1:#7ec2f3`,
    },
  }

  useEffect(() => {
    if (!account) return

    dispatch(fetchActivityAnalytics(account?.id, { type: 'content.activity.finish' }))
  }, [account])

  return (
    <Container className="card licenses-card">
      {isAccountsLoading ? (
        <div className="loading-content">
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton className="chart-loading" active title={false} paragraph={{ rows: 1 }} />
        </div>
      ) : (
        <div className="content">
          {!!data?.length && (
            <div className="header">
              <div className="title-container">
                <LineChartOutlined className="icon" />
                <p className="title">Finished activities</p>
              </div>

              <Button
                className="cta-button"
                type="secondary"
                icon={<PersonArrowRight24Regular style={{ width: 16 }} />}
                onClick={() => navigate(`/accounts/${accountId}/content`)}
              >
                Assign content
              </Button>
            </div>
          )}

          <div className="analytics-container">
            {data?.length ? (
              <Area {...config} />
            ) : (
              <div className="no-content">
                <div className="info">
                  <h4 className="title">
                    <LineChartOutlined className="icon" />
                    Track your team's activity
                  </h4>
                  <p className="text">Assign projects to your members and track their progress.</p>
                </div>

                <div className="actions">
                  <Button
                    className="cta-button"
                    type="secondary"
                    icon={<PersonArrowRight24Regular style={{ width: 16 }} />}
                    onClick={() => navigate(`/accounts/${accountId}/content`)}
                  >
                    Assign content
                  </Button>
                </div>

                <img
                  className="image"
                  src={theme === 'dark' ? TeamsEmptyActivitiesDark : TeamsEmptyActivities}
                  alt="No activity yet"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Container>
  )
}

export default ActivitiesCard
