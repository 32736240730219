import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const getAccounts = async (params) => {
  const route = 'accounts'
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getAccount = async (accountId) => {
  const route = `accounts/${accountId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postAccount = async (data) => {
  const route = `accounts`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const patchAccount = async (accountId, data) => {
  const route = `accounts/${accountId}`
  return API.patch(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postAccountImage = async (accountId, file) => {
  const route = `accounts/${accountId}/upload-image`
  return API.post(
    route,
    { image_file: file },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getAccountUsers = async (accountId, params) => {
  const route = `accounts/${accountId}/users`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postRemoveAccountUser = async (accountId, data) => {
  const route = `accounts/${accountId}/users/remove`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getAccountTeams = async (accountId, params) => {
  const route = `accounts/${accountId}/teams`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getAccountTeam = async (accountId, teamId) => {
  const route = `accounts/${accountId}/teams/${teamId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data.results))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postAccountTeam = async (accountId, data) => {
  const route = `accounts/${accountId}/teams`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const patchAccountTeam = async (accountId, teamId, data) => {
  const route = `accounts/${accountId}/teams/${teamId}`
  return API.patch(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteAccountTeam = async (accountId, teamId) => {
  const route = `accounts/${accountId}/teams/${teamId}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postAddTeamUser = async (accountId, teamId, data) => {
  const route = `accounts/${accountId}/teams/${teamId}/users/add`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postRemoveTeamUser = async (accountId, teamId, data) => {
  const route = `accounts/${accountId}/teams/${teamId}/users/remove`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getAccountAnalyticsLeaderboard = async (accountId, queryParams) => {
  const route = `accounts/${accountId}/analytics/leaderboard`
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getAccountProjectsAnalytics = async (accountId, queryParams) => {
  const route = `accounts/${accountId}/analytics/projects`
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getAccountPlaygroundsAnalytics = async (accountId, queryParams) => {
  const route = `accounts/${accountId}/analytics/playgrounds`
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getAccountAttempsAnalytics = async (accountId, queryParams) => {
  const route = `accounts/${accountId}/analytics/attempts`
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getAccountVersionsAnalytics = async (accountId, queryParams) => {
  const route = `accounts/${accountId}/analytics/versions`
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getAccountActivityAnalytics = async (accountId, queryParams) => {
  const route = `accounts/${accountId}/analytics/activity`
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postAccountAddLicense = async (accountId, data) => {
  const route = `accounts/${accountId}/licenses/assign`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postAccountRemoveLicense = async (accountId, data) => {
  const route = `accounts/${accountId}/licenses/remove`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

// Invites
const getAccountUserInvites = async (accountId, queryParams) => {
  const route = `accounts/${accountId}/user-invites`
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postAccountInviteLink = async (accountId, data) => {
  const route = `accounts/${accountId}/invite-links`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postAccountUserInvites = async (accountId, data) => {
  const route = `accounts/${accountId}/user-invites`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const patchAccountInviteLink = async (accountId, inviteId, data) => {
  const route = `accounts/${accountId}/invite-links/${inviteId}`
  return API.patch(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const patchAccountUserInvite = async (accountId, inviteId, data) => {
  const route = `accounts/${accountId}/user-invites/${inviteId}`
  return API.patch(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postAccountValidateEmails = async (data) => {
  const route = 'accounts/validate-emails'
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postAccountUserInviteResend = async (accountId, inviteId) => {
  const route = `accounts/${accountId}/user-invites/${inviteId}/resend`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  getAccounts,
  getAccount,
  postAccount,
  patchAccount,
  postAccountImage,
  getAccountUsers,
  postRemoveAccountUser,
  getAccountTeams,
  getAccountTeam,
  postAccountTeam,
  patchAccountTeam,
  deleteAccountTeam,
  postAddTeamUser,
  postRemoveTeamUser,
  getAccountAnalyticsLeaderboard,
  getAccountProjectsAnalytics,
  getAccountPlaygroundsAnalytics,
  getAccountAttempsAnalytics,
  getAccountVersionsAnalytics,
  getAccountActivityAnalytics,
  postAccountAddLicense,
  postAccountRemoveLicense,
  // Invites
  getAccountUserInvites,
  postAccountInviteLink,
  postAccountUserInvites,
  patchAccountInviteLink,
  patchAccountUserInvite,
  postAccountValidateEmails,
  postAccountUserInviteResend,
}
