const accountSizes = [
  {
    value: '1-4',
    label: '1-4 members',
  },
  {
    value: '5-10',
    label: '5-10 members',
  },
  {
    value: '11-20',
    label: '11-20 members',
  },
  {
    value: '21-50',
    label: '21-50 members',
  },
  {
    value: '51-100',
    label: '51-100 members',
  },
  {
    value: '100+',
    label: '100+ members',
  },
]

export default accountSizes
