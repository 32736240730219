import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.gray1};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  > .loading-content {
    padding: 30px 30px 10px;
  }

  > .content {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 8px;
    flex: 1;

    .more-licenses-box {
      position: absolute;
      top: 24px;
      right: 20px;

      .text {
        font-size: 13px;
        line-height: 16px;

        .small {
          font-size: 11px;
        }
      }
    }

    .licenses-info {
      flex: 1;
      padding: 30px 0px 10px 28px;

      .title-container {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 14px;

        .title {
          font-size: 16px;
          margin: 0px;
        }
      }

      .license-type-info {
        border-top: 1px solid ${(props) => props.theme.colors.gray4};
        padding: 8px 0px;

        .value {
          color: ${(props) => props.theme.colors.gray11};
          display: flex;
          align-items: center;
          gap: 5px;
          margin: 0px;

          .dot {
            height: 11px;
            width: 11px;
            display: inline-block;
            border-radius: 50%;
            background-color: ${(props) => props.theme.colors.gray7};

            &.assigned {
              background-color: ${(props) => props.theme.colors.cyan5};
            }

            &.available {
              background-color: ${(props) => props.theme.colors.purple5};
            }
          }
        }

        .name {
          color: ${(props) => props.theme.colors.gray7};
          margin: 0px;
        }
      }
    }

    .chart-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      padding: 30px 28px 0px 0px;

      .chart-box {
        height: 120px;
        width: 240px;
        position: relative;

        .info {
          text-align: center;
          position: absolute;
          left: 0px;
          right: 0px;
          bottom: 8px;

          .title {
            line-height: 22px;
            margin: 0px;
          }

          .text {
            color: ${(props) => props.theme.colors.gray7};
            margin: 0px;
          }
        }
      }
    }
  }

  @media ${device.xxlDown} {
    > .content {
      flex-direction: column;

      .license-type-info {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .chart-content {
        padding: 0px;
        margin: 0px auto;
      }
    }
  }

  @media ${device.xlDown} {
    flex: 0;

    > .content {
      flex-direction: row;
    }
  }

  @media ${device.lgDown} {
    > .content {
      flex-direction: column;

      .more-licenses-box {
        top: 14px;
      }
    }
  }
`
