import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import dayjs from 'dayjs'
import { Skeleton, Select, Progress, Tabs, Empty } from 'antd'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import { capitalize } from '@/utils/functions'
import FadeIn from '@/components/FadeIn'
import Activity from '@/components/Activity'
import HTMLBlock from '@/components/HTMLBlock'
import ModuleComments from '@/pages/MainPage/components/ModuleComments'
import { fetchAttemptsAnalytics, setModuleAttempt, setProjectAnalyticsModalOpen } from '@/store/accounts/actions'
import { Modal } from './styles'

const ModuleDetailModal = () => {
  const dispatch = useDispatch()

  const { theme, themeConfig } = useSelector((state) => state.app)
  const {
    allUsers,
    currentAccount: account,
    attemptsAnalytics,
    currentAttempt,
    isProjectAnalyticsModalOpen,
    isModuleAttemptsLoading,
  } = useSelector((state) => state.accounts)

  const moduleId = isProjectAnalyticsModalOpen?.projectId
  const userId = isProjectAnalyticsModalOpen?.userId

  const [selectedTab, setSelectedTab] = useState('preview')
  const attempt = attemptsAnalytics?.module_attempts?.find((a) => a.id === currentAttempt)

  // use attempt.activity_attempts data and simulate the activity.user_status of each activity
  const activitiesData = attemptsAnalytics?.activities?.map((a, i) => {
    const activityUserStatus = attempt?.activity_attempts?.find((activityAttempt) => activityAttempt?.id === a?.id)

    return { ...a, order: i, user_status: activityUserStatus }
  })

  const userData = allUsers?.find((data) => data?.user?.id === userId)
  // const startedAt = attempt?.started_at ? dayjs(attempt?.started_at).format('lll') : '—'
  // const startedAtFrom = attempt?.started_at && dayjs(attempt?.started_at).from()
  // const finishedAt = attempt?.finished_at ? dayjs(attempt?.finished_at).format('lll') : '—'
  // const finishedAtFrom = attempt?.finished_at && dayjs(attempt?.finished_at).from()
  // const timeInMin = parseInt(attempt?.lab_running_time / 60) || 0
  const scoring = Math.round((attempt?.scoring || 0) * 100, 2) || 0

  const [previewHtml, setPreviewHtml] = useState('')
  const [isPreviewLoading, setIsPreviewLoading] = useState(false)

  const handleLoadPreviewHtml = async () => {
    setIsPreviewLoading(true)

    try {
      if (attempt?.preview_html?.[0]?.preview_html) {
        setSelectedTab('preview')

        const previewHtml = await axios({
          method: 'GET',
          url: attempt?.preview_html?.[0]?.preview_html,
        })

        setPreviewHtml(previewHtml.data)
      } else {
        setSelectedTab('activities')
        setPreviewHtml()
      }
    } finally {
      setIsPreviewLoading(false)
    }
  }

  const tabItems = [
    {
      key: 'preview',
      label: 'Preview',
      children: (
        <div className="section module-preview-section">
          {isPreviewLoading ? (
            <div className="loading-content">
              <Skeleton active title={false} paragraph={{ rows: 4 }} />
              <Skeleton active title={false} paragraph={{ rows: 4 }} />
            </div>
          ) : previewHtml ? (
            <HTMLBlock
              className={`module-preview ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}
              content={previewHtml}
            />
          ) : currentAttempt ? (
            <div className="no-preview-available">
              <h3 className="title">Preview not available</h3>

              <p className="description">
                This project was started but doesn't have a preview yet as it's still being edited. Try again in a few
                minutes.
              </p>
            </div>
          ) : (
            <div className="no-preview-available">
              <Empty
                className="no-content"
                description="This project has not been started yet."
                style={{ marginTop: 40 }}
              />
            </div>
          )}
        </div>
      ),
    },
    ...(currentAttempt
      ? [
          {
            key: 'activities',
            label: 'Activities',
            children: (
              <div className="section">
                {activitiesData?.map((a) => (
                  <Activity key={a.id} activity={a} isReview />
                ))}
              </div>
            ),
          },
          {
            key: 'comments',
            label: (
              <div className="tab-item">
                Timeline and Comments
                {/* <Tag className="new-tag">New</Tag> */}
              </div>
            ),
            children: (
              <div className="section">
                <ModuleComments user={userData?.user} attempt={attempt} />
              </div>
            ),
          },
        ]
      : []),
  ]

  useEffect(() => {
    if (!currentAttempt) return

    handleLoadPreviewHtml()
  }, [currentAttempt])

  useEffect(() => {
    if (!attemptsAnalytics || currentAttempt) return

    dispatch(setModuleAttempt(attemptsAnalytics?.valid_module_attempt))
  }, [attemptsAnalytics])

  useEffect(() => {
    if (!isProjectAnalyticsModalOpen) {
      dispatch(setModuleAttempt(null))
      setPreviewHtml(null)
      setSelectedTab('preview')
      return
    }

    dispatch(fetchAttemptsAnalytics(account?.id, { user_id: userId, module_id: moduleId }))
  }, [isProjectAnalyticsModalOpen])

  return (
    <Modal
      className="project-analytics-modal"
      open={!!isProjectAnalyticsModalOpen}
      onCancel={() => dispatch(setProjectAnalyticsModalOpen(false))}
      footer={null}
      destroyOnClose
    >
      <div className="container">
        {isModuleAttemptsLoading || (attemptsAnalytics?.valid_module_attempt && !currentAttempt) ? (
          <div className="header is-loading">
            <Skeleton active />
            <br />
            <Skeleton active title={false} paragraph={{ rows: 4 }} />
            <Skeleton active />
            <Skeleton active title={false} paragraph={{ rows: 4 }} />
          </div>
        ) : (
          <>
            <div className="header">
              {renderModuleImage(attemptsAnalytics)}

              <div className="info">
                <h3 className="title">{attemptsAnalytics?.module_name}</h3>

                <h5 className="subtitle">
                  <Progress
                    className="progress-bar"
                    percent={scoring}
                    strokeColor={{
                      from: themeConfig?.colors.geekblue5,
                      to: themeConfig?.colors.cyan5,
                    }}
                    success={{ percent: 0 }}
                  />

                  <div className="member-info">
                    <span>resolved by</span> {capitalize(userData?.user?.first_name)}{' '}
                    {capitalize(userData?.user?.last_name)}
                  </div>
                </h5>
              </div>
            </div>

            {currentAttempt && (
              <div className="select-container">
                <Select
                  className="attempt-select"
                  value={currentAttempt}
                  placeholder="Select an attempt"
                  options={attemptsAnalytics?.module_attempts?.map((a, i) => ({
                    value: a.id,
                    label: `Attempt ${i + 1} — started ${dayjs(a?.started_at).format('lll')}`,
                  }))}
                  onChange={(newAttemptId) => dispatch(setModuleAttempt(newAttemptId))}
                  virtual={false}
                />
              </div>
            )}

            <div className="content">
              <FadeIn>
                <Tabs className="tabs-container" items={tabItems} activeKey={selectedTab} onChange={setSelectedTab} />
              </FadeIn>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default ModuleDetailModal
