import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;

  .header-container {
    height: 72px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;

    .title {
      margin: 0px;
    }
  }

  .table-content {
    height: calc(100% - 72px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    .table-header {
      background-color: ${(props) => props.theme.colors.gray1};
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 24px;

      .search-box {
        height: 32px;
        max-width: 300px;
        width: 100%;
        background-color: ${(props) => props.theme.colors.gray1};
        border: 1px solid ${(props) => props.theme.colors.base.gray5};
        border-radius: 2px;
        position: relative;
        display: flex;
        align-items: center;

        .ais-SearchBox {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;

          .ais-SearchBox-form {
            width: calc(100% - 32px);
            height: 100%;
            display: flex;
            align-items: center;

            input {
              width: 100%;
              height: 100%;
              font-size: 14px;
              font-weight: 400;
              border: 0px;
              outline: 0px;
              padding: 4px 11px;
            }

            .ais-SearchBox-loadingIndicator {
              margin-right: 4px;
              position: absolute;
              right: 38px;
            }

            .ais-SearchBox-submit,
            .ais-SearchBox-reset {
              display: none;
            }
          }
        }

        .search-icon-container {
          height: 100%;
          width: 32px;
          background-color: ${(props) => props.theme.colors.gray2};
          border: none;
          border-left: 1px solid ${(props) => props.theme.colors.base.gray5};
          position: absolute;
          top: 0px;
          right: 0px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            color: ${(props) => props.theme.colors.gray13}40;
            font-size: 16px;
          }
        }
      }
    }
  }

  @media ${device.mdDown} {
    .table-content {
      gap: 12px;
      padding: 0px;

      .table-header {
        height: 90px;
        border-top: 1px solid ${(props) => props.theme.colors.gray4};
        padding: 0px 14px;

        .search-box {
          max-width: initial;
        }
      }
    }
  }
`
