import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const postActivityReaction = async (activityId, data) => {
  const route = `activities/${activityId}/reactions`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteActivityReaction = async (activityId, data) => {
  const route = `activities/${activityId}/reactions`
  return API.delete(route, { data })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { postActivityReaction, deleteActivityReaction }
