import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  background-color: ${(props) =>
    localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray2 : props.theme.colors.gray10};
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  position: relative;

  > .content {
    max-width: 310px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    padding: 40px 0 40px 40px;
    z-index: 1;

    > .title {
      color: ${(props) => props.theme.colors.base.gray1};
      margin: 0px 0px 4px;
    }

    .text {
      color: ${(props) => props.theme.colors.base.gray1};
      margin: 0px;
    }
  }

  .image-container {
    position: absolute;
    bottom: -3px;
    right: -3px;

    .bg-image {
      position: relative;
      user-select: none;

      &.pattern {
        filter: blur(20px);
        transform: translate(132px, 0px) scale(1.1);
        position: static;
      }
    }
  }

  .actions {
    display: flex;
    gap: 12px;
    margin-top: 18px;

    .welcome-button {
      background-color: ${(props) => props.theme.colors.base.gray1};
      color: ${(props) => props.theme.colors.base.gray10};
      border-color: ${(props) => props.theme.colors.base.gray5};

      &:hover {
        background-color: ${(props) => props.theme.colors.base.gray5} !important;
        color: ${(props) => props.theme.colors.base.gray10};
        border-color: ${(props) => props.theme.colors.base.gray5};
      }
    }
  }

  @media ${device.xlDown} {
    flex: initial !important;

    > .content {
      align-items: center;
      text-align: center;
      padding: 0px 30px !important;
      margin: 0px auto;
    }
  }

  @media ${device.smDown} {
    > .content {
      .actions {
        flex-direction: column;
      }
    }
  }
`
