import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useSearchParams, Link } from 'react-router-dom'
import { Tabs } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { platformURL } from '@/helpers/env'
import Button from '@/components/Button'
import ProLabel from '@/components/ProLabel'
import ModulesCatalogTab from '../ModulesCatalogTab'
import CustomProjectsTab from '../CustomProjectsTab'
import AutoGradedProjectsTab from '../AutoGradedProjectsTab'
import { setContentTab, setUserSearchText } from '@/store/accounts/actions'
import { Container } from './styles'

const ContentTab = () => {
  const dispatch = useDispatch()
  const { accountId } = useParams()
  let [searchParams, setSearchParams] = useSearchParams()

  const tab = searchParams.get('tab')

  const { selectedContentTab } = useSelector((state) => state.accounts)

  const tabItems = [
    {
      key: 'projects',
      label: 'DataWars projects',
      children: <div className="tab-content">{selectedContentTab === 'projects' && <ModulesCatalogTab />}</div>,
    },
    {
      key: 'customProjects',
      label: 'Custom projects',
      children: <div className="tab-content">{selectedContentTab === 'customProjects' && <CustomProjectsTab />}</div>,
    },
    {
      key: 'autoGradedProjects',
      label: (
        <span style={{ display: 'flex', gap: 4 }}>
          Auto-graded projects
          <ProLabel />
        </span>
      ),
      children: (
        <div className="tab-content">
          {selectedContentTab === 'autoGradedProjects' && (
            <AutoGradedProjectsTab goToProjectsTab={() => handleChangeTab('projects')} />
          )}
        </div>
      ),
    },
  ]

  const handleChangeTab = (tab) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), tab })
  }

  useEffect(() => {
    dispatch(setUserSearchText(''))

    const isValidTab = ['projects', 'customProjects', 'autoGradedProjects'].includes(tab)

    if (tab && tab !== selectedContentTab && isValidTab) {
      dispatch(setContentTab(tab))
      return
    }

    if (!tab || !isValidTab) {
      dispatch(setContentTab('projects'))
    }
  }, [tab])

  useEffect(() => {
    return () => {
      dispatch(setUserSearchText(''))
    }
  }, [])

  return (
    <Container className="content-tab">
      <div className="header-container">
        <div className="info">
          <div className="title-container">
            <h4 className="title">Content</h4>
          </div>
        </div>

        {selectedContentTab === 'customProjects' && (
          <Link className="link" to={`${platformURL}custom-projects/${accountId}/create`} target="_blank">
            <Button type="secondary" icon={<PlusOutlined />} onClick={() => {}}>
              Create project
            </Button>
          </Link>
        )}
      </div>

      <div className="content">
        <Tabs activeKey={selectedContentTab} items={tabItems} onChange={handleChangeTab} />
      </div>
    </Container>
  )
}

export default ContentTab
