import { useSelector, useDispatch } from 'react-redux'
import { UsergroupAddOutlined, PlusOutlined } from '@ant-design/icons'
import Button from '@/components/Button'
import TeamItem from '@/pages/MainPage/components/TeamItem'
import TeamsTable from '../TeamsTable'
import { setTeamModalOpen } from '@/store/accounts/actions'
import { Container } from './styles'

const TeamsTab = () => {
  const dispatch = useDispatch()

  const { themeConfig } = useSelector((state) => state.app)
  const { teamsData, isTeamsLoading } = useSelector((state) => state.accounts)

  const renderEmptyState = () => {
    return (
      <div className="empty-state">
        <div className="empty-state-content">
          <div className="header">
            <div className="container">
              <UsergroupAddOutlined className="icon" />

              <h4 className="title">Organize your users in teams</h4>

              <p className="text">Assign content and track analytics for your teams</p>

              <Button type="secondary" onClick={() => dispatch(setTeamModalOpen('create'))}>
                Create team
              </Button>
            </div>
          </div>

          <div className="teams-container">
            <div className="row">
              <div className="team-card">
                <TeamItem
                  team={{
                    name: 'ML Engineers',
                    icon: 'fluent-Rocket24Regular',
                    color: themeConfig?.colors?.purple6,
                  }}
                  size="small"
                />
              </div>
            </div>

            <div className="row">
              <div className="team-card">
                <TeamItem
                  team={{
                    name: 'Trainees',
                    icon: 'fluent-PeopleAudience24Regular',
                    color: themeConfig?.colors?.geekblue6,
                  }}
                  size="small"
                />
              </div>

              <div className="team-card">
                <TeamItem
                  team={{
                    name: 'Data Scientists',
                    icon: 'fluent-BrainCircuit24Regular',
                    color: themeConfig?.colors?.red6,
                  }}
                  size="small"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Container className="teams-tab">
      <div className="header-container">
        <div className="info">
          <div className="title-container">
            <h4 className="title">Teams</h4>
          </div>
        </div>

        <Button type="secondary" icon={<PlusOutlined />} onClick={() => dispatch(setTeamModalOpen('create'))}>
          Create team
        </Button>
      </div>

      <div className="main-content">
        {isTeamsLoading || !teamsData || teamsData?.results?.length ? <TeamsTable /> : renderEmptyState()}
      </div>
    </Container>
  )
}

export default TeamsTab
