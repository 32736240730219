import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { PersonArrowRight24Regular, Copy24Regular } from '@fluentui/react-icons'
import { platformURL } from '@/helpers/env'
import { showToast } from '@/utils/toast'
import Button from '@/components/Button'
import ModuleBox from '@/components/ModuleBox'
import { setAssignModalOpen } from '@/store/modules/actions'
import GoogleClassromIcon from '@/assets/images/icons/google-classroom.png'
import MicrosoftTeamsIcon from '@/assets/images/icons/microsoft-teams.png'
import { Modal } from './styles'

const AssignModal = () => {
  const dispatch = useDispatch()

  const { isAssignModalOpen } = useSelector((state) => state.modules)

  const module = isAssignModalOpen
  const projectURL = module ? `${platformURL}project/${module?.id}` : null
  const googleClassroomURL = module
    ? `https://classroom.google.com/share?url=${projectURL}&title=${module?.name}&body=${module?.description}&itemtype=assignment`
    : null

  const microsoftTeamsURL = module
    ? `https://teams.microsoft.com/share?href=${projectURL}&msgText=${"Here's a new DataWars assignment"}&assignTitle=${module?.name}&assignInstr=${module?.description}`
    : null

  const copyToClipboard = () => {
    navigator.clipboard.writeText(projectURL)
    showToast('Link copied to clipboard')
  }

  const cleanup = () => {
    dispatch(setAssignModalOpen(false))
  }

  return (
    <Modal open={isAssignModalOpen} onCancel={cleanup} footer={null} destroyOnClose>
      <div className="container">
        <h4 className="title">
          <PersonArrowRight24Regular className="icon" />
          Assign the following project
        </h4>

        <ModuleBox module={module} disableClick />

        <p className="text">Assign this project using either Google Classrooms or with a direct link below.</p>

        <div className="quote">
          <p className="text">
            Once your invited members start their work on this project, you'll be able to see their progress in the
            Analytics section.
          </p>
        </div>

        <div className="actions">
          <Link className="share-google-classroom-btn" to={googleClassroomURL} target="_blank">
            <Button
              type="default"
              icon={<img className="icon" src={GoogleClassromIcon} alt="Add to Google Classroom" />}
            >
              Add to Google Classroom
            </Button>
          </Link>

          <Link className="share-microsoft-teams-btn" to={microsoftTeamsURL} target="_blank">
            <Button
              type="default"
              icon={<img className="icon" src={MicrosoftTeamsIcon} alt="Add to Microsoft Teams" />}
            >
              Add to Microsoft Teams
            </Button>
          </Link>

          <Button className="copy-link-button" type="primary" onClick={copyToClipboard}>
            <Copy24Regular className="icon" /> Copy link
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AssignModal
