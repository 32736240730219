const industries = [
  { value: 'aerospace', label: 'Aerospace' },
  { value: 'agriculture', label: 'Agriculture' },
  { value: 'automotive', label: 'Automotive' },
  { value: 'construction', label: 'Construction' },
  { value: 'education', label: 'Education' },
  { value: 'energy', label: 'Energy' },
  { value: 'fashion', label: 'Fashion' },
  { value: 'finance', label: 'Finance' },
  { value: 'food and beverage', label: 'Food and Beverage' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'hospitality', label: 'Hospitality' },
  { value: 'legal services', label: 'Legal Services' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'media and entertainment', label: 'Media and Entertainment' },
  { value: 'pharmaceutical', label: 'Pharmaceutical' },
  { value: 'real estate', label: 'Real Estate' },
  { value: 'retail', label: 'Retail' },
  { value: 'technology', label: 'Technology' },
  { value: 'telecommunications', label: 'Telecommunications' },
  { value: 'transportation', label: 'Transportation' },
]

export default industries
