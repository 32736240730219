import { useDispatch, useSelector } from 'react-redux'
import { Select, Tooltip, Spin, Tag } from 'antd'
import { isValidEmailFormat } from '@/helpers/formValidation'
import { validateInviteEmails } from '@/store/accounts/actions'
import { Container } from './styles'
import { useEffect } from 'react'

const EmailsInput = ({ emails, setEmails }) => {
  const dispatch = useDispatch()

  const { inviteEmails, isInviteModalOpen, isInviteLoading } = useSelector((state) => state.accounts)

  const handleRemoveEmail = (value) => {
    setEmails(emails.filter((i) => i !== value))
  }

  const handleChange = async (rawEmails) => {
    setEmails(rawEmails)

    const emails = rawEmails?.filter((email) => isValidEmailFormat(email))

    await dispatch(validateInviteEmails({ emails }))
  }

  useEffect(() => {
    if (!isInviteModalOpen) return

    handleChange([])
  }, [isInviteModalOpen])

  return (
    <Container className="emails-input-container">
      <Spin spinning={isInviteLoading}>
        <Select
          className="emails-input"
          value={emails}
          mode="tags"
          allowClear
          placeholder="Add emails to be invited"
          onChange={handleChange}
          onClear={() => handleChange([])}
          tagRender={({ label }) => {
            const hasEmailValidFormat = isValidEmailFormat(label)
            const hasEmailData = inviteEmails?.find((emailData) => emailData.email === label)

            let tooltipTitle = null
            let tagColor = 'default'

            if (!hasEmailValidFormat) {
              tooltipTitle = 'Email format is not valid'
              tagColor = 'warning'
            }

            if (hasEmailData && hasEmailData?.error) {
              tooltipTitle = hasEmailData?.error
              tagColor = 'error'
            }

            return (
              <Tooltip title={tooltipTitle}>
                <Tag
                  className="email-tag"
                  color={tagColor}
                  closeIcon
                  onClose={() => {
                    handleRemoveEmail(label)
                    handleChange(emails.filter((email) => email !== label))
                  }}
                >
                  {label}
                </Tag>
              </Tooltip>
            )
          }}
          tokenSeparators={[',', ';', ' ']}
          open={false}
          suffixIcon={false}
        />
      </Spin>
    </Container>
  )
}

export default EmailsInput
