import styled, { css, keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import TeamsEmptyState from '@/assets/images/empty-state-teams.png'
import TeamsEmptyStateDark from '@/assets/images/empty-state-teams-dark.png'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  > .header-container {
    min-height: 72px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 0px 24px;

    .info {
      display: flex;
      align-items: center;
      gap: 16px;

      .title-container {
        display: flex;
        display: flex;
        align-items: center;
        gap: 4px;

        .title {
          white-space: nowrap;
          margin: 0px;
        }
      }
    }
  }

  > .main-content {
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 20px;

    .empty-state {
      height: 100%;
      background-color: ${(props) => props.theme.colors.gray1};

      .empty-state-content {
        ${() =>
          localStorage.getItem('dw-theme') === 'dark'
            ? css`
                background: url(${TeamsEmptyStateDark}) no-repeat center top;
              `
            : css`
                background: url(${TeamsEmptyState}) no-repeat center center;
                background-size: cover;
              `}

        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 0px 60px;
        animation: 1s ${fadeInAnimation};

        > .header {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          text-align: center;
          flex: 1 0 50%;
          padding: 24px 36px;

          .container {
            .icon {
              font-size: 24px;
              color: ${(props) => props.theme.colors.geekblue5};
              margin-bottom: 10px;
            }

            .title {
              margin: 0px;
              line-height: 28px;
              margin-bottom: 0px;
            }

            .text {
              color: ${(props) => props.theme.colors.gray7};
              font-weight: 400;
              margin: 6px 0px 0px;
            }

            > button {
              margin-top: 16px;
            }
          }
        }

        .teams-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          gap: 16px;
          flex: 1 0 50%;
          padding: 24px;

          > .row {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 22px;
          }

          .team-card {
            background-color: ${(props) => props.theme.colors.gray1};
            border-radius: 10px;
            padding: 16px;
          }
        }
      }
    }
  }

  @media ${device.mdDown} {
    > .header-container {
      padding: 0px 12px;

      .info {
        .title-container {
          .title {
            font-size: 16px;
          }
        }
      }
    }

    > .main-content {
      padding: 0px;
    }
  }
`
